import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-ac2bb5a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { for: "email" }
const _hoisted_3 = {
  key: 0,
  class: "error-message"
}
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { for: "message" }
const _hoisted_6 = {
  key: 0,
  class: "error-message"
}
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = { ref: "recaptcha" }
const _hoisted_9 = {
  key: 0,
  class: "error-message"
}
const _hoisted_10 = ["disabled"]
const _hoisted_11 = {
  key: 0,
  class: "error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StaticLogo = _resolveComponent("StaticLogo")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['contact-container', { 'dark-mode': _ctx.isDarkMode }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(['card', 'contact-form', { 'dark-mode-card': _ctx.isDarkMode }])
    }, [
      _createVNode(_component_StaticLogo, {
        isDarkMode: _ctx.isDarkMode,
        basePixelSize: 8
      }, null, 8, ["isDarkMode"]),
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('contact.form.title')), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('contact.form.intro')), 1),
      _createElementVNode("form", {
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t('contact.form.emailLabel')), 1),
          _withDirectives(_createElementVNode("input", {
            type: "email",
            id: "email",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
            required: "",
            class: _normalizeClass({ 'dark-mode-input': _ctx.isDarkMode, 'error': !_ctx.isValidEmail && _ctx.isSubmitted })
          }, null, 2), [
            [_vModelText, _ctx.email]
          ]),
          (!_ctx.isValidEmail && _ctx.isSubmitted)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('contact.form.emailError')), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('contact.form.messageLabel')), 1),
          _withDirectives(_createElementVNode("textarea", {
            id: "message",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.message) = $event)),
            required: "",
            class: _normalizeClass({ 'dark-mode-input': _ctx.isDarkMode, 'error': !_ctx.isValidMessage && _ctx.isSubmitted })
          }, null, 2), [
            [_vModelText, _ctx.message]
          ]),
          (!_ctx.isValidMessage && _ctx.isSubmitted)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('contact.form.messageError')), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, null, 512),
          (!_ctx.isValidCaptcha && _ctx.isSubmitted)
            ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t('contact.form.captchaError')), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("button", {
          type: "submit",
          disabled: _ctx.isSubmitting || !_ctx.isValidForm
        }, _toDisplayString(_ctx.isSubmitting ? _ctx.$t('contact.form.sendingButton') : _ctx.$t('contact.form.sendButton')), 9, _hoisted_10)
      ], 32),
      (_ctx.submissionError)
        ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.$t('contact.form.submissionError')), 1))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}