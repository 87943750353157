<template>
  <div class="nav-dropdown" v-if="isVisible" ref="dropdown">
    <button :class="['hamburger', { 'is-active': showNavDropdown, 'dark-mode': localIsDarkMode }]" @click.stop="toggleNavDropdown" aria-label="Menu">
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>
    <transition name="fade">
      <div v-if="showNavDropdown" class="menu-dropdown" :class="{'dark-mode': localIsDarkMode}">
        <nav>
          <ul class="menu">
            <li><router-link to="/" @click="closeDropdown">{{ $t('dropdown.home') }}</router-link></li>
            <li><router-link to="/meet-the-team" @click="closeDropdown">{{ $t('dropdown.meetTheTeam') }}</router-link></li>
            <li>
              <a href="#" @click.prevent="toggleSubNav">{{ $t('dropdown.demoSite') }}</a>
              <transition name="fade">
                <ul v-if="showSubNav" class="sub-menu">
                  <li><router-link to="/demo-site" @click="closeDropdown">{{ $t('dropdown.demoSiteMain') }}</router-link></li>
                  <li><router-link to="/diagnostics" @click="closeDropdown">{{ $t('dropdown.diagnostics') }}</router-link></li>
                  <li><router-link to="/client-diagnostics" @click="closeDropdown">{{ $t('dropdown.clientDiagnostics') }}</router-link></li>
                </ul>
              </transition>
            </li>            
            <li><router-link to="/contact-us" @click="closeDropdown">{{ $t('dropdown.contactUs') }}</router-link></li>
          </ul>
        </nav>
        <div class="dropdown-footer">
          <div v-if="!showAuthComponent" class="auth-nav">
            <a href="#" class="auth-link special-auth-link" @click.stop="toggleAuthComponent" ref="authLink">
              {{ isAuthenticated ? $t('dropdown.showAccount') : $t('dropdown.createAccountLogin') }}
              <i class="fas fa-sign-in-alt"></i>
            </a>
          </div>
          <AuthComponent 
            v-else 
            :isDarkMode="localIsDarkMode" 
            @close="closeAuthComponent"
            ref="authComponent"
            class="auth-component"
          />
          <div class="toggle-container">
            <div class="toggle">
              <label class="switch">
                <input type="checkbox" v-model="localIsDarkMode" @change.stop="emitDarkModeToggle">
                <span class="slider round"></span>
              </label>
              <span class="toggle-label">{{ $t('dropdown.darkMode') }}</span>
            </div>
            <div class="toggle">
              <label class="switch" :class="{ 'disabled': isJazzLoading }">
                <input type="checkbox" v-model="localIsJazzMode" @change.stop="emitJazzModeToggle" :disabled="isJazzLoading">
                <span :class="['slider', 'round', { 'loading': isJazzLoading }]">
                  <div class="spinner"></div>
                </span>
              </label>
              <span class="toggle-label">{{ $t('dropdown.jazzMode') }}</span>
            </div>
            <div class="toggle">
              <label class="switch">
                <input type="checkbox" v-model="localIsDrawMode" @change="emitDrawModeToggle">
                <span class="slider round"></span>
              </label>
              <span class="toggle-label">{{ $t('sidebar.drawMode') }}</span>
            </div>
            <div class="language-switcher">
              <div class="dropdown" :class="{ 'open': isDropdownOpen }">
                <button @click.stop="toggleDropdown" :class="['dropbtn', { 'dark-mode': localIsDarkMode }]">
                  <img :src="getSelectedLanguageFlag()" :alt="selectedLanguage" />
                  {{ selectedLanguage }}
                  <i class="fas fa-chevron-down"></i>
                </button>
                <div :class="['dropdown-content', { 'dark-mode': localIsDarkMode }]">
                  <a v-for="lang in availableLanguages" :key="lang.code" @click.stop="changeLanguage(lang.code, $event)">
                    <img :src="lang.flag" :alt="lang.name" />
                    {{ lang.name }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>    
</template>

<script lang="ts">
import { defineComponent, ComponentPublicInstance } from 'vue';
import { mapGetters } from 'vuex';
import AuthComponent from '@/components/AuthComponent.vue';

export default defineComponent({
  name: 'DropdownMenu',
  components: {
    AuthComponent
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    isDarkModeInput: {
      type: Boolean,
      default: false,
    },
    isJazzModeInput: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:darkModeToggled', 'update:jazzModeToggled', 'update:drawModeToggled'],
  data() {
    return {
      localIsDarkMode: this.isDarkModeInput,
      localIsJazzMode: this.isJazzModeInput,            
      showNavDropdown: false,
      showAuthComponent: false,
      isDropdownOpen: false,
      showSubNav: true,
      selectedLanguage: 'English',
      availableLanguages: [
        { code: 'en', name: 'English', flag: require('@/assets/us-flag.svg') },   
        { code: 'ru', name: 'Russian', flag: require('@/assets/ru-flag.svg') },     
        { code: 'cn', name: 'Chinese', flag: require('@/assets/cn-flag.svg') },
        { code: 'th', name: 'Thai', flag: require('@/assets/th-flag.svg') },
      ],
      localIsDrawMode: this.isDrawModeInput,
      isJazzLoading: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
  },
  watch: {
    isDarkModeInput(newVal: boolean) {
      this.localIsDarkMode = newVal;
    },
    isJazzModeInput(newVal: boolean) {
      this.localIsJazzMode = newVal;
    }
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
  methods: {
    toggleNavDropdown(event: Event) {
      event.stopPropagation();
      this.showNavDropdown = !this.showNavDropdown;
      if (!this.showNavDropdown) {
        this.showAuthComponent = false;
      }
    },
    toggleSubNav() {
      this.showSubNav = !this.showSubNav;
    },
    toggleAuthComponent(event: Event) {
      event.stopPropagation();
      this.showAuthComponent = !this.showAuthComponent;
    },
    closeAuthComponent() {
      this.showAuthComponent = false;
    },
    emitDarkModeToggle(event: Event) {
      event.stopPropagation();
      this.$emit('update:darkModeToggled', this.localIsDarkMode);
    },
    toggleDropdown(event: Event) {
      event.stopPropagation();
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    changeLanguage(lang: string, event?: Event) {
      if (event) {
        event.stopPropagation();
      }
      this.$i18n.locale = lang;
      this.selectedLanguage = this.availableLanguages.find(l => l.code === lang)?.name || 'English';
      this.isDropdownOpen = false;
    },
    handleOutsideClick(event: MouseEvent) {
      const dropdownElement = this.$refs.dropdown as HTMLElement | undefined;
      const authComponentElement = this.$refs.authComponent as ComponentPublicInstance | undefined;
      const target = event.target as HTMLElement;
      
      // Check if the click is on the AuthComponent's close button
      const isAuthComponentCloseButton = target.closest('.close-button');
      
      if (dropdownElement && !dropdownElement.contains(target) && !isAuthComponentCloseButton) {
        this.showNavDropdown = false;
        this.showAuthComponent = false;
      } else if (authComponentElement && authComponentElement.$el && !authComponentElement.$el.contains(target) && !isAuthComponentCloseButton) {
        // If click is inside dropdown but outside AuthComponent and not on toggles or language picker, only close AuthComponent
        const isToggleOrLanguagePicker = target.closest('.toggle-container') || target.closest('.language-switcher');
        if (!isToggleOrLanguagePicker) {
          this.showAuthComponent = false;
        }
      }
    },
    closeDropdown() {
      this.showNavDropdown = false;
      this.showSubNav = false;
      this.showAuthComponent = false;
    },
    getSelectedLanguageFlag() {
      return this.availableLanguages.find(l => l.name === this.selectedLanguage)?.flag || this.availableLanguages[0].flag;
    },    
    emitDrawModeToggle() {
      this.$emit('update:drawModeToggled', this.localIsDrawMode);
    },
    emitJazzModeToggle(event: Event) {
      event.stopPropagation();
      if (!this.isJazzLoading) {
        this.isJazzLoading = true;
        this.$emit('update:jazzModeToggled', this.localIsJazzMode);
      }
    },
    updateJazzLoadingState(isLoading: boolean) {
      this.isJazzLoading = isLoading;
    },
  },
});
</script>

<style scoped>
.nav-dropdown {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999;
  user-select: none;  
}

.menu-dropdown {
  margin-top: -67px;
  position: absolute;
  right: 0;
  width: 250px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  overflow: hidden;
  transition: all 0.1s ease;
}

.menu-dropdown.dark-mode {
  background-color: #333;
  color: #fff;
}

.menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu li {
  padding: 0;
}

.menu li a,
.menu li {
  display: block;
  padding: 6px 20px;
  text-decoration: none;
  color: #333;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;
}

.menu-dropdown.dark-mode .menu li a {
  color: #fff;
}

.menu li a:hover {
  background-color: #f0f0f0;
  color: #007bff;
}

.menu-dropdown.dark-mode .menu li a:hover {
  background-color: #444;
}

.sub-menu {
  list-style-type: none;
  margin: 0;
}

.sub-menu li a {
  font-size: 14px;
  padding: 4px 20px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.dropdown-footer {
  padding: 20px;
  border-top: 1px solid #eee;
}

.menu-dropdown.dark-mode .dropdown-footer {
  border-top-color: #444;
}

.auth-nav {
  margin-bottom: 15px;
}

.auth-link {
  display: block;
  padding: 10px 0;
  text-decoration: none;
  color: #333;
  font-size: 16px;
  transition: color 0.3s;
  text-align: center;
}

.sidebar.dark-mode .auth-link {
  color: #f8f9fa;
}

.special-auth-link {
  background-color: #28a745;
  color: #ffffff !important;
  border-radius: 5px;
  padding: 10px 20px;
  transition: background-color 0.3s, transform 0.3s;
}

.special-auth-link:hover {
  background-color: #208538;
}

.menu-dropdown.dark-mode .special-auth-link {
  color: #28a745;
}

.menu-dropdown.dark-mode .special-auth-link:hover {
  color: #208538;
}

.toggle-container {
  display: flex;
  flex-direction: column;
}

.toggle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.toggle-label {
  font-size: 14px;
}

.hamburger {
  padding: 10px;
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  margin: 0;
  transition: transform 0.3s ease-in-out;
  user-select: none;  
}

.hamburger-box {
  width: 30px;
  height: 24px;
  display: inline-block;
  position: relative;
  z-index: 10000;
  padding-top: 20px;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 30px;
  height: 3px;
  background-color: #007bff;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

.hamburger.is-active .hamburger-inner {
  transform: rotate(45deg);
}

.hamburger.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

.hamburger.dark-mode .hamburger-inner,
.hamburger.dark-mode .hamburger-inner::before,
.hamburger.dark-mode .hamburger-inner::after {
  background-color: #007bff;
}

.dropbtn {
  color: #333;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  background-color: #f9f9f9;
}

.dropbtn img {
  width: 20px;
  height: 14px;
  margin-right: 10px;
}

.dropbtn.dark-mode {
  background-color: #333;
  color: #f9f9f9;
}

.dropdown-content {
  display: none;
  position: relative;
  background-color: #f9f9f9;
  width: 100%; /* Change this from min-width to width */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 5px;
  max-height: 200px; /* Add this to limit the height */
  overflow-y: auto; /* Add this to make it scrollable if needed */
}

.dropdown-content.dark-mode {
  background-color: #333;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.dropdown-content.dark-mode a {
  color: #f8f9fa;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.sidebar.dark-mode .dropdown-content a:hover {
  background-color: #444;
}

.dropdown-content img {
  width: 20px;
  height: 14px;
  margin-right: 10px;
}

.dropdown.open .dropdown-content {
  display: block;
}

.fa-chevron-down {
  margin-left: 5px;
}

@media (max-height: 480) {
  .sidebar-footer {
    padding-bottom: 60px;
  }
}

.auth-nav > a  {
  align-self: center;
  width: 67%;
  box-sizing: border-box;
  margin: 0 auto; /* Center horizontally */
}

.switch.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.switch.disabled .slider {
  cursor: not-allowed;
}

input:disabled + .slider {
  opacity: 0.6;
}

.spinner {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 3px;
  left: 5px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-top: 2px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  transition: .4s;
  opacity: 0;
  pointer-events: none;
}

input:checked + .slider .spinner {
  left: 29px;
}

.loading .spinner {
  opacity: 1;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.dark-mode .spinner {
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-top: 2px solid #007bff;
}
</style>
