<template>
  <div :class="{'profile': true, 'dark-mode': isDarkMode}">
    <div class="header">
      <div class="avatar-container">
        <div class="spinner"></div>
        <img class="avatar" src="@/assets/profile-photo.jpg" alt="Avatar" @load="onImageLoad" @error="onImageError" />
      </div>
      <h1>{{ name }}</h1>
      <p class="tagline">{{ $t('team.header.tagline') }}</p>
      <div class="social-icons">
        <a :href="$t('team.header.linkedin')" target="_blank"><i class="fab fa-linkedin"></i></a>
        <a :href="$t('team.header.github')" target="_blank"><i class="fab fa-github"></i></a>
      </div>
      <p>{{ $t('team.header.location') }}</p>
      <br/>
      <font-awesome-icon title="Display Contact Info" icon="address-book" size="2x" class="contact-icon" v-if="!showContact" @click="revealContact" />
      <p class="phone" v-if="showContact">{{ $t('team.header.phone') }}</p>
      <p class="email" v-if="showContact">{{ $t('team.header.email') }}</p>
    </div>
    
    <div :class="['card', 'summary', { 'dark-mode-card': isDarkMode }]">
      <h2>{{ $t('team.summary.title') }}</h2>
      <div class="summary-content">
        <p v-for="(summary, index) in $tm('team.summary.content')" :key="index">{{ summary }}</p>
      </div>
    </div>
    
    <div :class="['card', 'expertise', { 'dark-mode-card': isDarkMode }]">
      <h2>{{ $t('home.expertise.title') }}</h2>
      <div class="expertise-container">
        <div class="expertise-list">
          <div class="expertise-header">
            <span class="expertise-name" @click="toggleSort('name')">
              Name
              <i :class="getSortIcon('name')"></i>
            </span>
            <span :class="['expertise-years', { 'dark-mode': isDarkMode }]" @click="toggleSort('years')">
              Years
              <i :class="getSortIcon('years')"></i>
            </span>
          </div>
          <div v-for="(item, index) in sortedExpertiseItems" :key="index" class="expertise-item">
            <span class="expertise-skill">{{ item.name }}</span>
            <div class="expertise-bar-container">
              <div class="expertise-bar">
                <transition name="bar-fill">
                  <div v-if="showBars" class="expertise-fill" :style="{ width: `${item.years * 10}%` }">
                    <span :class="['expertise-years', { 'dark-mode': isDarkMode }]">{{ item.years }} years</span>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    
    <div :class="['card', 'experiences', { 'dark-mode-card': isDarkMode }]">
      <h2>{{ $t('team.experience.title') }}</h2>
      <div class="timeline">
        <div v-for="(experience, index) in $tm('team.experience.items')" :key="index" class="timeline-item">  
          <div class="timeline-content">
            <div class="experience-header" @click="toggleExperience(index)">
              <h3 class="experience-position">{{ experience.position }}</h3>
              <p :class="['experience-company', { 'dark-mode': isDarkMode }]">{{ experience.company }} | {{ experience.location }}</p>
              <p :class="['experience-duration', { 'dark-mode': isDarkMode }]">{{ experience.duration }}</p>
              <i :class="['fas', 'fa-caret-down', 'carrot-icon', { 'rotated': isExperienceOpen(index) }]"></i>
            </div>
            <transition name="fade">
              <div class="experience-description" v-if="isExperienceOpen(index)">
                <ul>
                  <li v-for="(description, descIndex) in experience.description" :key="descIndex">{{ description }}</li>
                </ul>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
    
    <div :class="['card', 'education', { 'dark-mode-card': isDarkMode }]">
      <h2>{{ $t('team.education.title') }}</h2>
      <h3>{{ $t('team.education.degree') }}</h3>      
      <p>{{ $t('team.education.school') }}</p>
    </div>    
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

interface ExpertiseItem {
  name: string;
  years: number;
}

type SortField = 'name' | 'years';
type SortDirection = 'asc' | 'desc' | null;

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Team",
  props: {
    isDarkMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      name: "Daniil Demidov",
      showContact: false,
      openExperiences: [] as number[],
      expertiseItems: [] as ExpertiseItem[],
      showBars: false,
      sortField: null as SortField | null,
      sortDirection: null as SortDirection
    };
  },
  computed: {
    sortedExpertiseItems(): ExpertiseItem[] {
      if (!this.sortField) return this.expertiseItems;

      return [...this.expertiseItems].sort((a, b) => {
        if (this.sortField === 'name') {
          return this.sortDirection === 'asc' 
            ? a.name.localeCompare(b.name) 
            : b.name.localeCompare(a.name);
        } else {
          return this.sortDirection === 'asc' 
            ? a.years - b.years 
            : b.years - a.years;
        }
      });
    }
  },
  created() {
    this.expertiseItems = [
      { name: 'C# / .NET', years: 7 },
      { name: 'Go', years: 2 },
      { name: 'Angular', years: 3 },
      { name: 'Vue.js', years: 2 },
      { name: 'SQL Server', years: 7 },
      { name: 'MongoDB', years: 3 },
      { name: 'Docker', years: 3 },
      { name: 'Azure', years: 5 },
      { name: 'AWS', years: 2 },
      { name: 'Agile / Scrum', years: 9 },
    ];
    
    this.sortField = 'years';
    this.sortDirection = 'asc';

    this.toggleSort('years');
  },
  mounted() {
    setTimeout(() => {
      this.showBars = true;
    }, 500);
  },
  methods: {
    toggleExperience(index: number) {
      const experienceIndex = this.openExperiences.indexOf(index);
      if (experienceIndex >= 0) {
        this.openExperiences.splice(experienceIndex, 1);
      } else {
        this.openExperiences.push(index);
      }
    },
    isExperienceOpen(index: number) {
      return this.openExperiences.includes(index);
    },
    revealContact() {
      this.showContact = true;
    },
    toggleSort(field: SortField) {
      if (this.sortField === field) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : this.sortDirection === 'desc' ? null : 'asc';
        if (!this.sortDirection) this.sortField = null;
      } else {
        this.sortField = field;
        this.sortDirection = 'asc';
      }
    },
    getSortIcon(field: SortField): string {
      if (this.sortField !== field) return 'fas fa-sort';
      return this.sortDirection === 'asc' ? 'fas fa-sort-up' : 'fas fa-sort-down';
    }
  },
});
</script>

<style scoped>
.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto', Arial, sans-serif;
  padding: 20px;
  transition: background-color 0.3s, color 0.3s;  
  user-select: none;
  max-width: 100%;
  overflow-x: hidden;
}

.profile.dark-mode {
  background-color: #222;
  color: #f8f9fa;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 4px solid #007bff;
  margin-bottom: 1rem;
  z-index: 100;
  position: relative;
}

.header h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: #007bff;
}

.tagline {
  font-size: 1.2rem;
  color: #6c757d;
  margin-bottom: 1rem;
}

.header p.phone {  
  user-select: text;
}

.header p.email {  
  user-select: text;
}

.social-icons {
  margin-bottom: 1rem;
}

.social-icons a {
  color: #007bff;
  font-size: 1.5rem;
  margin: 0 0.5rem;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #0056b3;
}

.card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  transition: background-color 0.3s, box-shadow 0.3s;
  margin-bottom: 20px;
  padding: 25px;
  box-sizing: border-box;
}

.card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.profile.dark-mode .card {
  background-color: #333;
  color: #f8f9fa;
}

.card h2 {
  color: #007bff;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  border-bottom: 2px solid #007bff;
  padding-bottom: 0.5rem;
}

.summary-content p {
  margin-bottom: 1rem;
  line-height: 1.6;
}

.expertise-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.expertise-header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.expertise-header .expertise-name,
.expertise-header .expertise-years {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9rem;
}

.expertise-header i {
  font-size: 0.6em;
}

.expertise-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  max-width: 500px;
}

.expertise-item {
  display: flex;
  align-items: center;
  gap: 15px;
}

.expertise-skill {
  width: 120px;
  font-weight: bold;
  text-align: left;
  flex-shrink: 0;
}

.expertise-bar-container {
  flex-grow: 1;
  width: 0;
}

.expertise-bar {
  height: 30px;
  background-color: #e0e0e0;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}

.expertise-fill {
  height: 100%;
  background-color: #007bff;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  box-sizing: border-box;
}

.expertise-years {
  color: #222;
}

.expertise-years.dark-mode {
  color: white;
  background-color: transparent;
}

.expertise-years.dark-mode-card {
  color: white;
}

.profile.dark-mode .expertise-bar {
  background-color: #444;
}

.profile.dark-mode .expertise-fill {
  background-color: #0056b3;
}

.bar-fill-enter-active {
  transition: width 1s ease-out;
}

.bar-fill-enter-from {
  width: 0% !important;
}

.contact-icon {
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  padding: 0.25rem .25rem;
  transition: background-color 0.3s, transform 0.3s;
  cursor: pointer;
}

.contact-icon:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.timeline {
  position: relative;
}

.timeline::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 2px;
  background-color: #007bff;
}

.timeline-item {
  position: relative;
  padding-left: 2rem;
  margin-bottom: 2rem;
}

.timeline-item::before {
  content: '';
  position: absolute;
  left: -5px;
  top: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #007bff;
}

.timeline-content {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.profile.dark-mode .timeline-content {
  background-color: #444;
}

.experience-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.experience-position {
  font-size: 1.3rem;
  color: #007bff;
  margin-bottom: 0.5rem;
  flex: 1 1 100%;
}

.experience-company, .experience-duration {
  color: #222;
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-left: 10px;
}

.experience-company.dark-mode, .experience-duration.dark-mode {
  color: white;
  background-color: #444;
}

.experience-description ul {
  list-style-type: none;
  padding-left: 0;
}

.experience-description li {
  margin-bottom: 0.5rem;
  position: relative;
  padding-left: 1.5rem;
}

.experience-description li::before {
  content: '•';
  color: #007bff;
  position: absolute;
  left: 0;
}

.carrot-icon {
  transition: transform 0.3s;
}

.carrot-icon.rotated {
  transform: rotate(180deg);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.eye-icon {
  font-size: 1.5rem;
  color: #007bff;
  cursor: pointer;
  transition: color 0.3s;
}

.eye-icon:hover {
  color: #0056b3;
}

@media (max-width: 768px) {
  .card {
    padding: 1.5rem;
  }
  
  .experience-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .experience-position, .experience-company, .experience-duration {
    margin-bottom: 0.25rem;
  }

  .expertise-list {
    max-width: 400px;
  }

  .expertise-skill {
    width: 100px;
  }
}

@media (max-width: 480px) {
  .expertise-controls {
    flex-direction: column;
    align-items: center;
  }

  .expertise-controls button {
    width: 100%;
    max-width: 200px;
  }

  .expertise-list {
    max-width: 300px;
  }

  .expertise-skill {
    width: 80px;
    font-size: 0.9em;
  }

  .expertise-bar {
    height: 25px;
  }

  .expertise-years {
    font-size: 0.8em;
  }
}

.avatar-container {
  position: relative;
}

.spinner {
  position: absolute;
  top: 32%;
  left: 35%;
  transform: translate(-50%, -50%);
  border: 4px solid rgba(0, 123, 255, 0.2);
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  z-index: 0
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>
