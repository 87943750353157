<template>
  <div class="drawing-wrapper" tabindex="0" @keydown="handleKeyDown">
    <div class="controls-container">
      <div class="button-container">
        <button @click="toggleActiveDrawing" :class="{ active: isActivelyDrawing }" class="control-button pencil-button">
          ✏️ {{ isActivelyDrawing ? 'Stop Drawing' : 'Start Drawing' }}
        </button>
        <button @click="undo" class="control-button undo-button" :disabled="!canUndo">↩️ Undo</button>
      </div>
      <div class="color-selector">
        <button 
          v-for="color in colors" 
          :key="color"
          @click="selectColor(color)"
          :class="['color-button', { active: currentColor === color }]"
          :style="{ backgroundColor: color }"
        ></button>
      </div>
    </div>
    <canvas 
      ref="drawingCanvas" 
      :class="{ 'active-canvas': isActivelyDrawing }"
      :style="{ pointerEvents: pointerEvents }"
      @mousedown="startDrawing"
      @mousemove="draw"
      @mouseup="stopDrawing"
      @mouseout="stopDrawing"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleTouchEnd"
    ></canvas>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

interface Point {
  x: number;
  y: number;
}

interface Mark {
  path: Point[];
  createdAt: number;
  opacity: number;
  color: string;
  pageWidth: number;
  pageHeight: number;
}

export default defineComponent({
  name: 'PencilDraw',
  data() {
    return {
      isActivelyDrawing: false,
      context: null as CanvasRenderingContext2D | null,
      currentMark: null as Mark | null,
      marks: [] as Mark[],
      undoneMarks: [] as Mark[],
      animationFrameId: null as number | null,
      canvasWidth: 0,
      canvasHeight: 0,
      isMouseDown: false,
      currentColor: '#FF9AA2',
      colors: [
        '#FF9AA2', '#FFB7B2', '#FFDAC1', '#E2F0CB',
        '#B5EAD7', '#C7CEEA', '#E0BBE4', '#957DAD',
      ],
      pointerEvents: 'none' as string,
    };
  },
  computed: {
    canUndo(): boolean {
      return this.marks.length > 0;
    },
  },
  watch: {
    isActivelyDrawing(newVal) {
      this.pointerEvents = newVal ? 'auto' : 'none';
    }
  },
  mounted() {
    this.setupCanvas();
    this.loadDrawing();
    window.addEventListener('resize', this.handleResize);
    this.startAnimation();
    this.positionControlsContainer();
    window.addEventListener('resize', this.positionControlsContainer);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('resize', this.positionControlsContainer);
    if (this.animationFrameId !== null) {
      cancelAnimationFrame(this.animationFrameId);
    }
  },
  methods: {
    setupCanvas() {
      const canvas = this.$refs.drawingCanvas as HTMLCanvasElement;
      this.canvasWidth = window.innerWidth;
      this.canvasHeight = window.innerHeight;
      canvas.width = this.canvasWidth;
      canvas.height = this.canvasHeight;
      this.context = canvas.getContext('2d');

      if (this.context) {
        this.context.lineWidth = 2;
        this.context.lineCap = 'round';
        this.context.lineJoin = 'round';
      }
    },
    positionControlsContainer() {
      const mainContent = document.querySelector('.main-content') as HTMLElement;
      const controlsContainer = document.querySelector('.controls-container') as HTMLElement;
      
      if (mainContent && controlsContainer) {
        const mainContentRect = mainContent.getBoundingClientRect();
        const controlsRect = controlsContainer.getBoundingClientRect();
        
        controlsContainer.style.left = `${mainContentRect.left + (mainContentRect.width / 2) - (controlsRect.width / 2)}px`;
        controlsContainer.style.bottom = '20px';
      }
    },
    startDrawing(e: MouseEvent) {
      if (this.isActivelyDrawing && this.context) {
        this.isMouseDown = true;
        const { x, y } = this.getCoordinates(e);
        this.currentMark = {
          path: [{ x, y }],
          createdAt: Date.now(),
          opacity: 1,
          color: this.currentColor,
          pageWidth: this.canvasWidth,
          pageHeight: this.canvasHeight,
        };
        this.context.beginPath();
        this.context.moveTo(x, y);
        this.context.strokeStyle = this.currentColor;
      }
    },
    draw(e: MouseEvent) {
      if (this.isActivelyDrawing && this.isMouseDown && this.currentMark && this.context) {
        const { x, y } = this.getCoordinates(e);
        this.currentMark.path.push({ x, y });
        this.context.lineTo(x, y);
        this.context.stroke();
      }
    },
    stopDrawing() {
      if (this.currentMark) {
        this.marks.push(this.currentMark);
        this.saveCurrentState();
        this.currentMark = null;
        this.isMouseDown = false;
        this.undoneMarks = [];
      }
    },
    getCoordinates(e: MouseEvent): Point {
      const canvas = this.$refs.drawingCanvas as HTMLCanvasElement;
      const rect = canvas.getBoundingClientRect();
      const scaleX = canvas.width / rect.width;
      const scaleY = canvas.height / rect.height;
      return {
        x: (e.clientX - rect.left) * scaleX,
        y: (e.clientY - rect.top) * scaleY
      };
    },
    toggleActiveDrawing() {
      this.isActivelyDrawing = !this.isActivelyDrawing;
    },
    drawMark(mark: Mark) {
      if (this.context) {
        const scaleX = this.canvasWidth / mark.pageWidth;
        const scaleY = this.canvasHeight / mark.pageHeight;
        
        this.context.beginPath();
        this.context.globalAlpha = mark.opacity;
        this.context.strokeStyle = mark.color;
        mark.path.forEach((point, index) => {
          const scaledX = point.x * scaleX;
          const scaledY = point.y * scaleY;
          if (index === 0) {
            this.context!.moveTo(scaledX, scaledY);
          } else {
            this.context!.lineTo(scaledX, scaledY);
          }
        });
        this.context.stroke();
        this.context.globalAlpha = 1;
      }
    },
    startAnimation() {
      const animate = () => {
        this.updateAndDrawMarks();
        this.animationFrameId = requestAnimationFrame(animate);
      };
      this.animationFrameId = requestAnimationFrame(animate);
    },
    saveCurrentState() {
      const currentState = {
        marks: [...this.marks],
        undoneMarks: [...this.undoneMarks],
        savedAt: Date.now(),
      };
      if (this.currentMark) {
        currentState.marks.push(this.currentMark);
      }
      localStorage.setItem('global-drawing', JSON.stringify(currentState));
    },
    loadDrawing() {
      const savedData = localStorage.getItem('global-drawing');
      if (savedData) {
        const { marks, undoneMarks, savedAt } = JSON.parse(savedData);
        const now = Date.now();
        const timePassed = now - savedAt;

        this.marks = marks.filter((mark: Mark) => {
          const totalAge = timePassed + (savedAt - mark.createdAt);
          if (totalAge < 60000) {
            mark.opacity = 1 - (totalAge / 60000);
            return true;
          }
          return false;
        });

        this.undoneMarks = undoneMarks || [];
      }
    },
    handleResize() {
      this.setupCanvas();
      this.positionControlsContainer();
    },
    undo() {
      if (this.canUndo) {
        const lastMark = this.marks.pop();
        if (lastMark) {
          this.undoneMarks.push(lastMark);
          this.saveCurrentState();
          this.redrawAllMarks();
        }
      }
    },
    redrawAllMarks() {
      if (this.context) {
        this.context.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
        this.marks.forEach(mark => this.drawMark(mark));
      }
    },
    updateAndDrawMarks() {
      if (this.context) {
        this.context.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
        const now = Date.now();
        this.marks = this.marks.filter(mark => {
          const age = now - mark.createdAt;
          if (age < 60000) {
            mark.opacity = 1 - (age / 60000);
            this.drawMark(mark);
            return true;
          }
          return false;
        });
        if (this.currentMark) {
          this.drawMark(this.currentMark);
        }
        this.saveCurrentState();
      }
    },
    handleKeyDown(e: KeyboardEvent) {
      if (e.ctrlKey && e.key === 'z') {
        e.preventDefault();
        this.undo();
      }
    },
    selectColor(color: string) {
      this.currentColor = color;
    },
    handleTouchStart(e: TouchEvent) {
      e.preventDefault();
      const touch = e.touches[0];
      this.startDrawing({
        clientX: touch.clientX,
        clientY: touch.clientY,
      } as MouseEvent);
    },
    handleTouchMove(e: TouchEvent) {
      e.preventDefault();
      const touch = e.touches[0];
      this.draw({
        clientX: touch.clientX,
        clientY: touch.clientY,
      } as MouseEvent);
    },
    handleTouchEnd(e: TouchEvent) {
      e.preventDefault();
      this.stopDrawing();
    },
  },
});
</script>

<style scoped>
.drawing-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  pointer-events: none;
}

.controls-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  z-index: 1002;
  pointer-events: auto;
  user-select: none;
}

.button-container {
  display: flex;
  gap: 10px;
}

.control-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background-color: #007bff;
  transition: background-color 0.3s;
}

.control-button:hover {
  background-color: #0056b3;
}

.control-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.pencil-button.active {
  background-color: #28a745;
}

.color-selector {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 400px;
}

.color-button {
  width: 30px;
  height: 30px;
  border: 2px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s;
}

.color-button:hover {
  transform: scale(1.1);
}

.color-button.active {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #007bff;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

canvas.active-canvas {
  pointer-events: auto;
}

@media (prefers-color-scheme: dark) {
  .control-button {
    background-color: #0d6efd;
  }

  .control-button:hover {
    background-color: #0b5ed7;
  }

  .control-button:disabled {
    background-color: #6c757d;
  }

  .pencil-button.active {
    background-color: #198754;
  }

  .color-button {
    border-color: #343a40;
  }

  .color-button.active {
    box-shadow: 0 0 0 2px #343a40, 0 0 0 4px #0d6efd;
  }
}
</style>