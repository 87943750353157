<template>
  <transition name="sidebar">
    <div v-if="isSidebarVisible" class="sidebar" :class="{ 'collapsed': isSidebarCollapsed, 'dark-mode': localIsDarkMode }">
      <div class="sidebar-header">
        <StaticLogo v-if="!isSidebarCollapsed" :isDarkMode="localIsDarkMode" :basePixelSize="4" />
        <button class="collapse-toggle" @click="collapseSidebar" :aria-label="isSidebarCollapsed ? $t('sidebar.expandSidebar') : $t('sidebar.collapseSidebar')">
          <i :class="['fas', 'carrot-icon', {'fa-chevron-left': !isSidebarCollapsed}, {'fa-chevron-right': isSidebarCollapsed}]"></i>
        </button>
      </div>
      <nav v-if="!isSidebarCollapsed" class="sidebar-nav">
        <ul class="menu">
          <li><router-link to="/">{{ $t('sidebar.home') }}</router-link></li>
          <li><router-link to="/meet-the-team">{{ $t('sidebar.meetTheTeam') }}</router-link></li>
          <li>
              <a href="#" @click.prevent="toggleSubNav">{{ $t('dropdown.demoSite') }}</a>
              <transition name="fade">
                <ul v-if="showSubNav" class="sub-menu">
                  <li><router-link to="/demo-site">{{ $t('dropdown.demoSiteMain') }}</router-link></li>
                  <li><router-link to="/diagnostics">{{ $t('dropdown.diagnostics') }}</router-link></li>
                  <li><router-link to="/client-diagnostics">{{ $t('dropdown.clientDiagnostics') }}</router-link></li>
                </ul>
              </transition>
            </li>
          <li><router-link to="/contact-us">{{ $t('sidebar.contactUs') }}</router-link></li>
        </ul>
      </nav>
      <div v-if="!isSidebarCollapsed" class="sidebar-footer">
        <div class="toggle-container">
          <div class="toggle">
            <label class="switch">
              <input type="checkbox" v-model="localIsDarkMode" @change="emitDarkModeToggle">
              <span class="slider round"></span>
            </label>
            <span class="toggle-label">{{ $t('sidebar.darkMode') }}</span>
          </div>
         <div class="toggle">
            <label class="switch">
              <input type="checkbox" v-model="localIsJazzMode" @change="emitJazzModeToggle" :disabled="isJazzLoading">
              <span :class="['slider', 'round', { 'loading': isJazzLoading }]">
                <div class="spinner"></div>
              </span>
            </label>
            <span class="toggle-label">{{ $t('sidebar.jazzMode') }}</span>
          </div>
          <div class="toggle">
            <label class="switch">
              <input type="checkbox" v-model="localIsDrawMode" @change="emitDrawModeToggle">
              <span class="slider round"></span>
            </label>
            <span class="toggle-label">{{ $t('sidebar.drawMode') }}</span>
          </div>          
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StaticLogo from '@/components/StaticLogo.vue';

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Sidebar',
  components: {
    StaticLogo
  },
  props: {
    showSidebar: {
      type: Boolean,
      default: true,
    },
    isDarkModeInput: {
      type: Boolean,
      default: false,
    },
    isJazzModeInput: {
      type: Boolean,
      default: false,
    },
    isDrawModeInput: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:sideBarToggled', 'update:sideBarCollapsed', 'update:darkModeToggled', 'update:jazzModeToggled', 'update:drawModeToggled'],
  data() {
    return {
      isSidebarCollapsed: false,
      isSidebarVisible: false,
      localIsDarkMode: this.isDarkModeInput,
      localIsJazzMode: this.isJazzModeInput,
      isDropdownOpen: false,
      showSubNav: true,
      localIsDrawMode: this.isDrawModeInput,
      isJazzLoading: false,
    };
  },
  watch: {
    isDarkModeInput(newVal) {
      this.localIsDarkMode = newVal;
    },
    isJazzModeInput(newVal) {
      this.localIsJazzMode = newVal;
    },
    isDrawModeInput(newVal) {
      this.localIsDrawMode = newVal;
    },
  },
  mounted() {
    this.computeSidebar();
    window.addEventListener('resize', this.computeSidebar);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.computeSidebar);
  },
  methods: {
    computeSidebar() {
      this.isSidebarVisible = window.innerWidth > 1100;
      this.$emit('update:sideBarToggled', this.isSidebarVisible);
    },
    collapseSidebar() {
      this.isSidebarCollapsed = !this.isSidebarCollapsed;      
      this.$emit('update:sideBarCollapsed', this.isSidebarCollapsed);
    },
    emitDarkModeToggle() {
      this.$emit('update:darkModeToggled', this.localIsDarkMode);
    },
    emitJazzModeToggle() {
      if (!this.isJazzLoading) {
        this.isJazzLoading = true;
        this.$emit('update:jazzModeToggled', this.localIsJazzMode);
      }
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },        
    toggleSubNav() {
      this.showSubNav = !this.showSubNav;
    },
    emitDrawModeToggle() {
      this.$emit('update:drawModeToggled', this.localIsDrawMode);
    },
    updateJazzLoadingState(isLoading: boolean) {
      this.isJazzLoading = isLoading;
    },
  },
});
</script>

<style scoped>
.sidebar {
  background-color: #ffffff;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition: width 0.3s ease, background-color 0.3s ease;
  user-select: none;
  width: 250px;
  z-index: 9999;
}

.sidebar.collapsed {
  width: 60px;
}

.sidebar.dark-mode {
  background-color: #333;
  color: #f8f9fa;
}

.sidebar-header {
  align-items: center;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.sidebar.dark-mode .sidebar-header {
  border-bottom-color: #444;
}

.sidebar-header h2 {
  color: #007bff;
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.collapse-toggle {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.carrot-icon {
  color: #007bff;
  font-size: 20px;
  transition: transform 0.3s ease;
}

.sidebar-nav {
  flex-grow: 1;
  padding: 20px 0px 0px 0px;
}

.menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu li {
  margin: 10px 0;
  padding: 0;
}

.menu li a,
.menu li .demo-site-link {
  color: #333;
  display: block;
  font-size: 18px;
  padding: 10px 20px;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.menu li a:hover,
.menu li a.router-link-active,
.menu li .demo-site-link:hover {
  background-color: #f0f0f0;
}

.sub-menu {
  list-style-type: none;
  margin: 0;
  padding-left: 20px;
}

.sub-menu li a {
  font-size: 14px;
  padding: 4px 20px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.sidebar.dark-mode .menu li a,
.sidebar.dark-mode .menu li .demo-site-link {
  color: #f8f9fa;
}

.sidebar.dark-mode .menu li a:hover,
.sidebar.dark-mode .menu li a.router-link-active,
.sidebar.dark-mode .menu li .router-link:hover,
.sidebar.dark-mode .menu li a.demo-site-link-active,
.sidebar.dark-mode .menu li .demo-site-link:hover {
  background-color: #444;
}

.demo-site-item i {
  margin-right: 10px;
}

.demo-site-link {
  color: #007bff;
  transition: background-color 0.3s, transform 0.3s;
}

.demo-site-link:hover {
  transform: translateY(-2px);
}

.sidebar.dark-mode .demo-site-link {
  color: #ffffff;
}

.sidebar.dark-mode .demo-site-link:hover {
  background-color: #357abd;
}

.toggle {
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  white-space: nowrap;
  width: 100%;
  position: relative;
}

.switch {
  display: inline-block;
  flex-shrink: 0;
  height: 24px;
  margin-right: 10px;
  position: relative;
  width: 50px;
}

.switch input {
  height: 0;
  opacity: 0;
  width: 0;
}

.slider {
  background-color: #ccc;
  border-radius: 34px;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}

.slider:before {
  background-color: white;
  border-radius: 50%;
  bottom: 2px;
  content: "";
  height: 20px;
  left: 2px;
  position: absolute;
  transition: .4s;
  width: 20px;
}

input:checked + .slider {
  background-color: #007bff;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.toggle-label {
  font-size: 14px;
  white-space: nowrap;
  margin-left: 10px;
}

.spinner {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 3px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-top: 2px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  transition: .4s;
  opacity: 0;
  pointer-events: none;
}

input:checked + .slider .spinner {
  left: 29px; /* Adjust this value as needed */
}

.loading .spinner {
  opacity: 1;
}

.switch.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.switch.disabled .slider {
  cursor: not-allowed;
}

input:disabled + .slider {
  opacity: 0.6;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.dark-mode .spinner {
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-top: 2px solid #007bff;
}

.sidebar-enter-active, .sidebar-leave-active {
  transition: transform 0.3s ease;
}

.sidebar-enter-from, .sidebar-leave-to {
  transform: translateX(-100%);
}

@media (max-width: 1100px) {
  .sidebar {
    transform: translateX(-100%);
  }
  .sidebar.visible {
    transform: translateX(0);
  }
}

.sidebar-footer {
  align-items: center;
  border-top: 1px solid #eee;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  width: 100%;
}

.sidebar.dark-mode .sidebar-footer {
  border-top-color: #444;
}

.footer-nav {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 50%;
}

.footer-nav > a > i {
  padding-left: 6px;
}

.toggle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.sidebar-footer > div > div:nth-child(3) {
  margin-bottom: 0;
}

@media (max-height: 600px) {
  .sidebar-footer {
    padding-bottom: 60px;
  }
}

.footer-nav > a {
  align-self: center;
  box-sizing: border-box;
  margin: 0 auto;
  margin-bottom: 20px;
  width: 50%;
}

.sidebar:not(.collapsed) .footer-nav {
  opacity: 1;
  transition: none;
}

.sidebar.collapsed .footer-nav {
  opacity: 0;
  transition: none;
}

</style>