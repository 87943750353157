import axios, { AxiosError } from 'axios';
import { ContactRequest, ContactResponse } from '@/models/contact.model';
import { settings } from '@/config/settings';
import { Profile, UpdateProfileResponse } from '@/store/modules/profile.store';
import store from '@/store'; 

const API_BASE_URL = settings.apiBaseUrl;

export const submitContact = async (payload: ContactRequest): Promise<ContactResponse> => {
  try {
    const response = await axios.post<ContactResponse>(`${API_BASE_URL}/Contact/submit`, payload);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<{ message: string }>;
      throw new Error(axiosError.response?.data?.message || 'An error occurred while submitting the form');
    }
    throw error;
  }
};

export interface SendVerificationRequest {
  email: string;
}

export interface VerifyRequest {
  email: string;
  verificationCode: string;
}

export interface VerifyResponse {
  token: string;
}

export const sendVerification = async (payload: SendVerificationRequest): Promise<void> => {
  try {
    await axios.post(`${API_BASE_URL}/User/send-verification`, payload);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<{ message: string }>;
      throw new Error(axiosError.response?.data?.message || 'An error occurred while sending verification code');
    }
    throw error;
  }
};

export const verifyCode = async (payload: VerifyRequest): Promise<VerifyResponse> => {
  try {
    const response = await axios.post<VerifyResponse>(`${API_BASE_URL}/User/verify`, payload);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<{ message: string }>;
      throw new Error(axiosError.response?.data?.message || 'An error occurred while verifying the code');
    }
    throw error;
  }
};

export async function updateProfile(profile: Profile): Promise<UpdateProfileResponse> {
  const token = store.getters['auth/getToken'];
  const response = await axios.put<UpdateProfileResponse>(
    `${API_BASE_URL}/Profile/profile`,
    profile,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  return response.data;
}

export async function fetchProfile(): Promise<{ success: boolean; message: string | null; data: Profile }> {
  const token = store.getters['auth/getToken'];
  try {
    const response = await axios.get<{ success: boolean; message: string | null; data: Profile }>(
      `${API_BASE_URL}/Profile/profile`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );    
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      return { success: false, message: 'Profile not found', data: { username: '', phoneNumber: '', siteUrl: '' } };
    }
    throw error;
  }
}

export const checkUsernameExists = async (username: string): Promise<boolean> => {
  const token = store.getters['auth/getToken'];
  try {
    const response = await axios.get<{ exists: boolean }>(
      `${API_BASE_URL}/Profile/check-username`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: { username }
      }
    );
    return response.data.exists;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<{ message: string }>;
      throw new Error(axiosError.response?.data?.message || 'An error occurred while checking the username');
    }
    throw error;
  }
};

export interface DiagnosticsInfo {
  applicationName: string;
  applicationVersion: string;
  environmentName: string;
  operatingSystem: string;
  machineName: string;
  aspNetCoreVersion: string;
  dotNetCoreVersion: string;
  serverTime: string;
  serverTimeZone: string;
  processorCount: number;
  totalMemory: number;
  workingSet: number;
  databaseInfo: {
    connectionStatus: string;
    databaseName: string;
    userCount: number;
  };
  configurationSettings: {
    [key: string]: string;
  };
}

export async function fetchDiagnosticsInfo(): Promise<DiagnosticsInfo> {
  try {
    const response = await axios.get<DiagnosticsInfo>(
      `${API_BASE_URL}/Diagnostics/diagnostics-info`
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<{ message: string }>;
      throw new Error(axiosError.response?.data?.message || 'An error occurred while fetching diagnostics information');
    }
    throw error;
  }
}