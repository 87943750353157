<template>
  <div class="app-container" :class="{ 'dark-mode': isDarkMode }">
    <Sidebar 
      ref="sidebar"
      :showSidebar="showSidebar"
      :isDarkModeInput="isDarkMode"
      :isJazzModeInput="isJazzMode"
      :isDrawModeInput="isDrawMode"
      @update:sideBarToggled="sidebarToggled"
      @update:sideBarCollapsed="sidebarCollapsed"
      @update:darkModeToggled="darkModeToggled"
      @update:jazzModeToggled="jazzModeToggled"
      @update:drawModeToggled="drawModeToggled"
    />
    <div class="main-wrapper">
      <TopNav 
        v-if="showTopNav"
        :isDarkMode="isDarkMode"
      />
      <DropdownMenu 
        ref="dropdownMenu"
        :isVisible="!showSidebar"
        :isDarkModeInput="isDarkMode"
        :isJazzModeInput="isJazzMode"
        @update:darkModeToggled="darkModeToggled"
        @update:jazzModeToggled="jazzModeToggled"
        @update:drawModeToggled="drawModeToggled"
      />
      <div :class="['main-content', { 'sidebar-collapsed': isSidebarCollapsed || !showSidebar, 'top-nav-active': showTopNav }]">
        <router-view :key="$route.fullPath" :isDarkMode="isDarkMode" />
      </div>
      <div :class="['site-footer', { 'dark-mode': isDarkMode }]">
        <p>© 2024 DEV1 Consulting, LLC. All rights reserved.</p>
      </div>
    </div>
    <JazzPlayer ref="jazzPlayer" 
      @mounted="onJazzPlayerMounted" 
      @loadingStateChange="updateJazzLoadingState"
      :isVisible="isJazzMode" 
    />
    <PencilDraw v-if="isDrawMode" @update:drawMode="drawModeToggled" />
  </div>
</template>

<script lang="ts">
import { defineComponent, defineAsyncComponent, onMounted, onBeforeUnmount, ref } from 'vue';
import { useStore } from 'vuex';
import DropdownMenu from './components/DropdownMenu.vue';
import Sidebar from './components/Sidebar.vue';
import TopNav from './components/TopNav.vue';
import { saveState, loadState } from './helpers/storageHelper';
import { RootState } from '@/store/modules/auth.store';
import PencilDraw from '@/components/PencilDraw.vue';

export default defineComponent({
  name: 'App',
  components: {
    Sidebar,
    DropdownMenu,
    TopNav,
    JazzPlayer: defineAsyncComponent(() => import('./components/JazzPlayer.vue')),
    PencilDraw
  },
  setup() {
    const store = useStore<RootState>();
    const tokenExpirationInterval = ref<NodeJS.Timeout | null>(null);

    const startTokenExpirationCheck = () => {
      return setInterval(() => {
        store.dispatch('auth/checkTokenExpiration');
      }, 60000);
    };

    onMounted(() => {
      tokenExpirationInterval.value = startTokenExpirationCheck();
    });

    onBeforeUnmount(() => {
      if (tokenExpirationInterval.value) {
        clearInterval(tokenExpirationInterval.value);
      }
    });

    return {
      store,
      tokenExpirationInterval,
    };
  },
  data() {
    return {
      isDarkMode: loadState('isDarkMode') || false,
      showSidebar: true,
      isSidebarCollapsed: false,
      isJazzMode: false,
      isJazzPlayerMounted: false,
      pendingJazzAction: null as null | 'play' | 'pause',
      isDrawMode: false,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    showTopNav(): boolean {
      return this.windowWidth > 1100;
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    sidebarToggled(value: boolean) {
      this.showSidebar = value;
    },
    sidebarCollapsed(value: boolean) {
      this.isSidebarCollapsed = value;
    },
    darkModeToggled(value: boolean) {
      this.isDarkMode = value;
      saveState('isDarkMode', value);
      document.body.classList.toggle('dark-mode', value); 
    },
    async jazzModeToggled(value: boolean) {
      this.isJazzMode = value;
      await this.$nextTick();
      if (this.isJazzPlayerMounted) {
        if (value) {
          this.playJazz();
        } else {
          this.pauseJazz();
        }
      }
    },
    onJazzPlayerMounted() {
      this.isJazzPlayerMounted = true;
      if (this.isJazzMode) {
        this.playJazz();
      }
    },
    playJazz() {
      const jazzPlayer = this.$refs.jazzPlayer as any;
      if (jazzPlayer && typeof jazzPlayer.play === 'function') {
        try {
          const result = jazzPlayer.play();
          if (result && typeof result.then === 'function') {
            // If play() returns a Promise
            result
              .then(() => this.updateJazzLoadingState(false))
              .catch((error: Error) => {
                console.error('Error playing jazz:', error);
                this.updateJazzLoadingState(false);
              });
          } else {
            // If play() doesn't return a Promise
            this.updateJazzLoadingState(false);
          }
        } catch (error) {
          console.error('Error playing jazz:', error);
          this.updateJazzLoadingState(false);
        }
      } else {
        console.error('Jazz player or play method not found');
        this.updateJazzLoadingState(false);
      }
    },
    pauseJazz() {
      const jazzPlayer = this.$refs.jazzPlayer as any;
      if (jazzPlayer && typeof jazzPlayer.pause === 'function') {
        jazzPlayer.pause();
      } else {
        console.error('Jazz player or pause method not found');
      }
      this.updateJazzLoadingState(false);
    },
    drawModeToggled(value: boolean) {
      this.isDrawMode = value;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    updateJazzLoadingState(isLoading: boolean) {
      if (this.$refs.sidebar) {
        (this.$refs.sidebar as any).updateJazzLoadingState(isLoading);
      }
      if (this.$refs.dropdownMenu) {
        (this.$refs.dropdownMenu as any).updateJazzLoadingState(isLoading);
      }
    },
  },
});
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

/* Remove the default white border around the app */
#app {
  border: none;
}

.app-container {
  display: flex;
  min-height: 100vh;
  width: 100%;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.main-content {
  flex-grow: 1;
  transition: margin-left 0.3s ease, width 0.3s ease;
  margin-left: 250px;
  margin-top: 10px;
}

.main-content.sidebar-collapsed {
  width: 100%;
  margin-left: 0;
}

.dark-mode {
  background-color: #333;
  color: #f8f9fa;
}

.site-footer {
  text-align: center;
  padding: 10px;
  color: #333;
  margin-top: auto;
  transition: margin-left 0.3s ease, width 0.3s ease;
  margin-left: 250px;
  font-size: 18px;
  user-select: none;
}

.main-wrapper.sidebar-collapsed .site-footer {
  margin-left: 0;
}

.dark-mode .site-footer {
  color: #f8f9fa;
}

/* Media query for screens smaller than 1100px */
@media (max-width: 1100px) {
  .site-footer {
    margin-left: 0 !important;
  }
}

@media (max-width: 820px) {
  .site-footer{
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .site-footer{
    font-size: 12px;
  }
}

.main-content.top-nav-active {
  padding-top: 60px; /* Adjust this value based on your TopNav height */
}

@media (max-width: 1100px) {
  .main-content.top-nav-active {
    padding-top: 0;
  }
  .main-content {
    margin-top: 0px;
  }
}
</style>
