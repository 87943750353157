<template>
  <div class="logo-container" :class="{ 'dark-mode': isDarkMode }">
    <div class="logo-text">
      <div v-for="(letter, letterIndex) in logoLetters" :key="letterIndex" :class="['letter', { 'small-letter': letter.length === 24 }]">
        <div 
          v-for="(pixel, pixelIndex) in letter" 
          :key="pixelIndex" 
          class="pixel" 
          :style="pixelStyle(letterIndex, pixelIndex, pixel)"
        ></div>
      </div>
    </div>
    <div class="consulting-text-container">
      <span 
        v-for="(char, index) in 'CONSULTING'" 
        :key="index" 
        class="consulting-char"
        :style="consultingCharStyle(index)"
      >{{ char }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Logo',
  props: {
    isDarkMode: {
      type: Boolean,
      default: false
    },
    basePixelSize: {
      type: Number,
      default: 4
    }
  },
  data() {
    return {
      logoLetters: [
        // D
        [1,1,1,0,0,1,0,0,1,0,1,0,0,1,0,1,0,0,1,0,1,0,0,1,0,1,1,1,0,0],
        // E
        [1,1,1,1,0,1,0,0,0,0,1,1,1,0,0,1,0,0,0,0,1,0,0,0,0,1,1,1,1,0],
        // V
        [1,0,0,0,1,1,0,0,0,1,1,0,0,0,1,0,1,0,1,0,0,1,0,1,0,0,0,1,0,0],
        // 1
        [0,0,1,0,0,1,1,0,0,0,1,0,0,0,1,0,0,0,1,0,0,1,1,1]
      ]
    };
  },
  computed: {
    pixelSize(): string {
      return `${this.basePixelSize}px`;
    },
    logoWidth(): number {
      return this.logoLetters.length * (this.basePixelSize * 29);
    },
  },
  methods: {
    pixelStyle(letterIndex: number, pixelIndex: number, isOn: number) {
      if (!isOn) return { opacity: 0 };
      const delay = (letterIndex * 0.2 + pixelIndex * 0.01) + 's';
      return {
        animationDelay: delay,
        backgroundColor: this.getPixelColor(letterIndex, pixelIndex)
      };
    },
    getPixelColor(letterIndex: number, pixelIndex: number) {
      const blueHue = 220;
      const baseLightness = this.isDarkMode ? 60 : 40;
      const lightnessVariation = (letterIndex * 5 + pixelIndex) % 20 - 10;
      const lightness = Math.max(0, Math.min(100, baseLightness + lightnessVariation));
      return `hsl(${blueHue}, 70%, ${lightness}%)`;
    },
    consultingCharStyle(index: number) {
      return {
        animationDelay: `${index * 0.1}s`
      };
    }
  }
});
</script>

<style scoped>
.logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 0;
}

.logo-text {
  display: flex;
  gap: 20px;
}

.letter {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 4px;
}

.letter.small-letter {
  grid-template-columns: repeat(4, 1fr);
}

.pixel {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  opacity: 0;
  transform: scale(0);
  animation: pixelAppear 0.5s forwards;
}

@keyframes pixelAppear {
  0% {
    opacity: 0;
    transform: scale(0) rotate(180deg);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.2) rotate(90deg);
  }
  100% {
    opacity: 1;
    transform: scale(1) rotate(0deg);
  }
}

.dark-mode .pixel {
  filter: brightness(1.2);
}

.consulting-text-container {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 464px;
}

.consulting-char {
  font-family: 'Font Awesome 5 Free', monospace;
  font-weight: 860;
  color: hsl(220, 68%, 41%);
  text-transform: uppercase;
  -webkit-font-smoothing: none;
  -moz-osx-font-smoothing: grayscale;
  flex: 1;
  text-align: center;
  opacity: 0;
  animation: charAppear 0.5s forwards;
}

@keyframes charAppear {
  0% {
    opacity: 0;
    transform: scale(0) rotate(180deg);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.2) rotate(90deg);
  }
  100% {
    opacity: 1;
    transform: scale(1) rotate(0deg);
  }
}

.dark-mode .consulting-char {
  color: hsl(220, 67%, 62%);
}

@media (max-width: 768px) {
  .logo-container {
    height: 160px;
  }
  .pixel {
    width: 12px;
    height: 12px;
  }
  .consulting-text-container {
    width: 378px;
  }
}

@media (max-width: 480px) {
  .logo-container {
    height: 120px;
  }
  .pixel {
    width: 8px;
    height: 8px;
  }
  .consulting-text-container {
    width: 294px;
  }
}
</style>