import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store';
import { FontAwesomeIcon } from './fontawesome';
import enLocale from './locales/en.json';
import ruLocale from './locales/ru.json';
import thLocale from './locales/th.json';
import cnLocale from './locales/cn.json';
import { createI18n } from 'vue-i18n'

// Explicitly set feature flags
// @ts-ignore
window.__VUE_OPTIONS_API__ = true
// @ts-ignore
window.__VUE_PROD_DEVTOOLS__ = false
// @ts-ignore
window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false

const i18n = createI18n({
    legacy: false,
    locale: 'en',
    messages: {
      en: enLocale,
      ru: ruLocale,
      th: thLocale,
      cn: cnLocale
    }
})

store.dispatch('auth/initializeAuth');

const app = createApp(App);
app.use(i18n)
app.use(router);
app.use(store);
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');