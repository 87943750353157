import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2e496aaf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hero" }
const _hoisted_2 = { class: "about" }
const _hoisted_3 = { class: "services" }
const _hoisted_4 = { class: "service-cards" }
const _hoisted_5 = { class: "expertise" }
const _hoisted_6 = { class: "expertise-grid" }
const _hoisted_7 = { class: "portfolio" }
const _hoisted_8 = { class: "project-cards" }
const _hoisted_9 = { class: "contact" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['home-container', { 'dark-mode': _ctx.isDarkMode }])
  }, [
    _createElementVNode("section", _hoisted_1, [
      _createVNode(_component_Logo, { isDarkMode: _ctx.isDarkMode }, null, 8, ["isDarkMode"]),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('home.hero.tagline')), 1),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.navigateToContact && _ctx.navigateToContact(...args)))
      }, _toDisplayString(_ctx.$t('home.hero.cta')), 1)
    ]),
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('home.about.title')), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('home.about.content')), 1)
    ]),
    _createElementVNode("section", _hoisted_3, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('home.services.title')), 1),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$tm('home.services.items'), (service, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass(['service-card', { 'dark-mode-card': _ctx.isDarkMode }])
          }, [
            _createElementVNode("h3", null, _toDisplayString(_ctx.$t(`home.services.items[${index}].title`)), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t(`home.services.items[${index}].description`)), 1)
          ], 2))
        }), 128))
      ])
    ]),
    _createElementVNode("section", _hoisted_5, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('home.expertise.title')), 1),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$tm('home.expertise.items'), (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "expertise-item"
          }, _toDisplayString(item), 1))
        }), 128))
      ])
    ]),
    _createElementVNode("section", _hoisted_7, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('home.portfolio.title')), 1),
      _createElementVNode("div", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$tm('home.portfolio.projects'), (project, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass(['project-card', { 'dark-mode-card': _ctx.isDarkMode }])
          }, [
            _createElementVNode("h3", null, _toDisplayString(project.title), 1),
            _createElementVNode("p", null, _toDisplayString(project.description), 1)
          ], 2))
        }), 128))
      ])
    ]),
    _createElementVNode("section", _hoisted_9, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('home.contact.title')), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('home.contact.content')), 1),
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.navigateToContact && _ctx.navigateToContact(...args)))
      }, _toDisplayString(_ctx.$tm('home.contact.cta')), 1)
    ])
  ], 2))
}