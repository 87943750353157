import { Module, ActionContext } from 'vuex';
import { RootState } from '@/store/modules/auth.store';
import { fetchDiagnosticsInfo, DiagnosticsInfo } from '@/services/api.service';

export interface DiagnosticsState {
  status: 'idle' | 'loading' | 'success' | 'error';
  diagnosticsInfo: DiagnosticsInfo | null;
  error: string | null;
}

export const diagnosticsStore: Module<DiagnosticsState, RootState> = {
  namespaced: true,
  state: {
    status: 'idle',
    diagnosticsInfo: null,
    error: null,
  },
  mutations: {
    setStatus(state: DiagnosticsState, status: 'idle' | 'loading' | 'success' | 'error') {
      state.status = status;
    },
    setDiagnosticsInfo(state: DiagnosticsState, diagnosticsInfo: DiagnosticsInfo | null) {
      state.diagnosticsInfo = diagnosticsInfo;
    },
    setError(state: DiagnosticsState, error: string | null) {
      state.error = error;
    },
  },
  actions: {
    async fetchDiagnosticsInfo({ commit }: ActionContext<DiagnosticsState, RootState>) {
      commit('setStatus', 'loading');
      try {
        const response = await fetchDiagnosticsInfo();
        commit('setDiagnosticsInfo', response);
        commit('setStatus', 'success');
      } catch (error) {
        // Gulp!
      }
    },
  },
  getters: {
    diagnosticsStatus: (state: DiagnosticsState) => state.status,
    diagnosticsError: (state: DiagnosticsState) => state.error,
    getDiagnosticsInfo: (state: DiagnosticsState) => state.diagnosticsInfo,
  },
};