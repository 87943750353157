<template>
  <div :class="['diagnostics-console', { 'dark-mode': isDarkMode }]">
    <div class="console-header">
      <h2>{{ $t('clientDiagnostics.title') }}</h2>
      <div class="console-controls">
        <button @click="toggleLiveUpdate" :class="{ active: isLiveUpdate }">
          <span class="icon" :class="{ 'live-icon': isLiveUpdate }">{{ isLiveUpdate ? '◉' : '○' }}</span> {{ $t('clientDiagnostics.liveUpdateButton') }}
        </button>
        <button @click="refreshData" :disabled="isLoading || isLiveUpdate">
          <span class="icon">↻</span> {{ $t('clientDiagnostics.refreshDataButton') }}
        </button>
      </div>
    </div>

    <div v-if="isInitialLoading" class="loading-overlay">
      <div class="spinner"></div>
    </div>

    <div v-if="error" class="error-message">
      {{ $t('clientDiagnostics.errorLoading') }}
    </div>
    
    <div v-if="clientInfo" class="console-grid">
      <!-- System Overview Card -->
      <div class="console-card system-info">
        <h3>{{ $t('clientDiagnostics.systemOverviewTitle') }}</h3>
        <div class="info-grid">
          <div class="info-item" v-for="(value, key) in systemOverview" :key="key">
            <span class="label">{{ formatConfigKey(key) }}:</span>
            <span class="value">{{ value }}</span>
          </div>
        </div>
      </div>

      <!-- Browser Information -->
      <div class="console-card browser-info">
        <h3>{{ $t('clientDiagnostics.browserInfoTitle') }}</h3>
        <div class="info-grid">
          <div class="info-item" v-for="(value, key) in browserInfo" :key="key">
            <span class="label">{{ formatConfigKey(key) }}:</span>
            <span class="value">{{ value }}</span>
          </div>
        </div>
      </div>

      <!-- Hardware Information -->
      <div class="console-card hardware-info">
        <h3>{{ $t('clientDiagnostics.hardwareInfoTitle') }}</h3>
        <div class="info-grid">
          <div class="info-item" v-for="(value, key) in hardwareInfo" :key="key">
            <span class="label">{{ formatConfigKey(key) }}:</span>
            <span class="value">{{ value }}</span>
          </div>
        </div>
      </div>
      
      <!-- Network Information -->
      <div class="console-card network-info">
        <h3>{{ $t('clientDiagnostics.networkInfoTitle') }}</h3>
        <div class="info-grid">
          <div class="info-item" v-for="(value, key) in networkInfo" :key="key">
            <span class="label">{{ formatConfigKey(key) }}:</span>
            <span class="value">{{ value }}</span>
          </div>
        </div>
      </div>

      <!-- Performance Metrics -->
      <div class="console-card performance-metrics">
        <h3>{{ $t('clientDiagnostics.performanceMetricsTitle') }}</h3>
        <div class="chart-container">
          <LineChart 
            v-if="chartData.performanceMetrics"
            :data="chartData.performanceMetrics"
            :options="lineChartOptions"
          />
        </div>
      </div>
      
      <!-- Time Information -->
      <div class="console-card time-info">
        <h3>{{ $t('clientDiagnostics.timeInfoTitle') }}</h3>
        <div class="digital-clock">{{ currentTime }}</div>
        <div class="timezone-info">{{ timezoneInfo }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Chart, registerables, ChartData, ChartOptions } from 'chart.js';
import { Line as LineChart } from 'vue-chartjs';

Chart.register(...registerables);

interface ChartDataSet {
  performanceMetrics: ChartData<'line'> | null;
}

interface PerformanceDataPoint {
  time: string;
  fps: number;
  memory: number;
}

// Extend Performance interface to include memory
declare global {
  interface Performance {
    memory?: {
      usedJSHeapSize: number;
      totalJSHeapSize: number;
      jsHeapSizeLimit: number;
    };
  }
}

export default defineComponent({
  name: 'ClientDiagnostics',
  components: {
    LineChart
  },
  props: {
    isDarkMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentTime: '',
      timezoneInfo: '',
      clockInterval: null as number | null,
      isLiveUpdate: true,
      liveUpdateInterval: null as number | null,
      isInitialLoading: true,
      chartData: {
        performanceMetrics: null
      } as ChartDataSet,
      clientInfo: null as any,
      error: null as string | null,
      performanceHistory: [] as PerformanceDataPoint[],
      lineChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'FPS'
            }
          },
          y1: {
            type: 'linear' as const,
            display: true,
            position: 'right' as const,
            grid: {
              drawOnChartArea: false,
            },
            title: {
              display: true,
              text: 'Memory (MB)'
            }
          },
          x: {
            title: {
              display: true,
              text: 'Time'
            }
          }
        },
        plugins: {
          legend: {
            display: true,
            position: 'top' as const,
          }
        },
        animation: {
          duration: 0
        }
      } as ChartOptions<'line'>,
      lastFrameTime: 0
    };
  },
  computed: {
    isLoading(): boolean {
      return this.isInitialLoading && !this.clientInfo;
    },
    systemOverview() {
      if (!this.clientInfo) return {};
      return {
        OperatingSystem: this.clientInfo.operatingSystem,
        Platform: this.clientInfo.platform,
        Language: this.clientInfo.language,
      };
    },
    browserInfo() {
      if (!this.clientInfo) return {};
      return {
        Name: this.clientInfo.browserName,
        Version: this.clientInfo.browserVersion,
        UserAgent: this.clientInfo.userAgent,
        CookiesEnabled: this.clientInfo.cookiesEnabled,
        DoNotTrack: this.clientInfo.doNotTrack,
      };
    },
    hardwareInfo() {
      if (!this.clientInfo) return {};
      return {
        CPUCores: this.clientInfo.cpuCores,
        DeviceMemory: this.clientInfo.deviceMemory,
        MaxTouchPoints: this.clientInfo.maxTouchPoints,
        ScreenResolution: this.clientInfo.screenResolution,
      };
    },
    networkInfo() {
      if (!this.clientInfo) return {};
      return {
        ConnectionType: this.clientInfo.connectionType,
        EffectiveType: this.clientInfo.effectiveType,
        Downlink: this.clientInfo.downlink,
        RTT: this.clientInfo.rtt,
        OnLine: this.clientInfo.onLine,
      };
    },
  },
  methods: {
    formatConfigKey(key: string): string {
      return key.replace(/([A-Z])/g, ' $1').trim();
    },
    async gatherClientInfo() {
      try {
        const connection = (navigator as any).connection || {};
        const clientInfo = {
          // System Overview
          operatingSystem: this.getOS(),
          platform: navigator.platform,
          language: navigator.language,

          // Browser Information
          browserName: this.getBrowserName(),
          browserVersion: this.getBrowserVersion(),
          userAgent: navigator.userAgent,
          cookiesEnabled: navigator.cookieEnabled,
          doNotTrack: navigator.doNotTrack,

          // Hardware Information
          cpuCores: navigator.hardwareConcurrency || 'Unknown',
          deviceMemory: (navigator as any).deviceMemory ? `${(navigator as any).deviceMemory} GB` : 'Unknown',
          maxTouchPoints: navigator.maxTouchPoints,
          screenResolution: `${window.screen.width}x${window.screen.height}`,

          // Network Information
          connectionType: connection.type || 'Unknown',
          effectiveType: connection.effectiveType || 'Unknown',
          downlink: connection.downlink ? `${connection.downlink} Mbps` : 'Unknown',
          rtt: connection.rtt ? `${connection.rtt} ms` : 'Unknown',
          onLine: navigator.onLine ? 'Yes' : 'No',
        };
        this.clientInfo = clientInfo;
        this.updateChartData();
      } catch (e) {
        this.error = 'Error gathering client information';
      } finally {
        this.isInitialLoading = false;
      }
    },
    getOS() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      const platform = window.navigator.platform.toLowerCase();
      if (userAgent.indexOf("win") > -1) return "Windows";
      if (userAgent.indexOf("mac") > -1) return "macOS";
      if (userAgent.indexOf("linux") > -1) return "Linux";
      if (userAgent.indexOf("android") > -1) return "Android";
      if (userAgent.indexOf("like mac") > -1) return "iOS";
      return platform;
    },
    getBrowserName() {
      const userAgent = navigator.userAgent;
      let browserName;

      if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = "Chrome";
      } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = "Firefox";
      } else if (userAgent.match(/safari/i)) {
        browserName = "Safari";
      } else if (userAgent.match(/opr\//i)) {
        browserName = "Opera";
      } else if (userAgent.match(/edg/i)) {
        browserName = "Edge";
      } else {
        browserName = "Unknown";
      }

      return browserName;
    },
    getBrowserVersion() {
      const userAgent = navigator.userAgent;
      let browserName = this.getBrowserName();
      let versionRegex;

      switch (browserName) {
        case "Chrome":
          versionRegex = /(?:chrome|chromium|crios)\/(\d+(\.\d+)?)/i;
          break;
        case "Firefox":
          versionRegex = /(?:firefox|fxios)\/(\d+(\.\d+)?)/i;
          break;
        case "Safari":
          versionRegex = /version\/(\d+(\.\d+)?)/i;
          break;
        case "Opera":
          versionRegex = /(?:opera|opr)\/(\d+(\.\d+)?)/i;
          break;
        case "Edge":
          versionRegex = /(?:edge|edg)\/(\d+(\.\d+)?)/i;
          break;
        default:
          return "Unknown";
      }

      const match = userAgent.match(versionRegex);
      return match ? match[1] : "Unknown";
    },
    updateClock(): void {
      const now = new Date();
      this.currentTime = now.toLocaleTimeString();
      this.timezoneInfo = `${Intl.DateTimeFormat().resolvedOptions().timeZone} (UTC${-now.getTimezoneOffset() / 60})`;
    },
    async refreshData(): Promise<void> {
      await this.gatherClientInfo();
      this.updateChartData();
    },
    toggleLiveUpdate(): void {
      this.isLiveUpdate = !this.isLiveUpdate;
      if (this.isLiveUpdate) {
        this.startLiveUpdate();
      } else {
        this.stopLiveUpdate();
      }
    },
    startLiveUpdate(): void {
      this.refreshData();
      this.liveUpdateInterval = window.setInterval(this.refreshData, 5000);
    },
    stopLiveUpdate(): void {
      if (this.liveUpdateInterval) {
        clearInterval(this.liveUpdateInterval);
        this.liveUpdateInterval = null;
      }
    },
    updateChartData(): void {
      const currentTime = new Date().toLocaleTimeString();
      const fps = this.calculateFPS();
      const memory = this.getMemoryUsage();

      this.performanceHistory.push({ time: currentTime, fps, memory });
      if (this.performanceHistory.length > 20) {
        this.performanceHistory.shift();
      }

      const labels = this.performanceHistory.map(item => item.time);
      this.chartData.performanceMetrics = {
        labels,
        datasets: [
          {
            label: 'FPS',
            data: this.performanceHistory.map(item => item.fps),
            borderColor: 'rgba(75, 192, 192, 1)',
            tension: 0.1,
            yAxisID: 'y'
          },
          {
            label: 'Memory Usage (MB)',
            data: this.performanceHistory.map(item => item.memory),
            borderColor: 'rgba(153, 102, 255, 1)',
            tension: 0.1,
            yAxisID: 'y1'
          }
        ]
      };
    },
    calculateFPS(): number {
      const fps = performance.now() - this.lastFrameTime;
      this.lastFrameTime = performance.now();
      return Math.round(1000 / fps);
    },
    getMemoryUsage(): number {
      if (performance.memory) {
        return Math.round(performance.memory.usedJSHeapSize / (1024 * 1024));
      }
      return 0;
    }
  },
  mounted() {
    this.gatherClientInfo();
    this.updateClock();
    this.clockInterval = window.setInterval(this.updateClock, 1000);
    this.startLiveUpdate();
  },
  beforeUnmount() {
    if (this.clockInterval) {
      clearInterval(this.clockInterval);
    }
    this.stopLiveUpdate();
  }
});
</script>

<style scoped>
.diagnostics-console {
  font-family: 'Roboto', Arial, sans-serif;
  background-color: #f0f0f0;
  color: #333;
  padding: 20px 20px 100px 20px;
  height: 90%;
}

.diagnostics-console.dark-mode {
  background-color: #1e1e1e;
  color: #f0f0f0;
}

.console-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

h2 {
  font-size: 2.2em;
  margin: 0;
  color: #007bff;
}

.console-controls button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.console-controls button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.console-controls .icon {
  margin-right: 5px;
}

.console-controls .live-icon {
  color: #28a745;
}

.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-message {
  color: #dc3545;
  text-align: center;
  font-size: 1.2em;
  margin-top: 20px;
}

.console-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.console-card {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.dark-mode .console-card {
  background-color: #2c2c2c;
}

.console-card h3 {
  font-size: 1.3em;
  margin-bottom: 15px;
  color: #007bff;
}

.info-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.info-item {
  display: flex;
  align-items: flex-start;
  font-size: 0.9em;
  margin-bottom: 5px;
}

.label {
  font-weight: bold;
  margin-right: 10px;
  white-space: nowrap;
  flex-shrink: 0;
}

.value {
  flex-grow: 1;
  word-break: break-word;
  white-space: normal;
  text-align: left;
}

.chart-container {
  height: 200px;
}

.digital-clock {
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.timezone-info {
  font-size: 1em;
  text-align: center;
  color: #666;
}

.dark-mode .timezone-info {
  color: #aaa;
}

@media (max-width: 1100px) {
  .diagnostics-console {
    padding: 80px 20px 100px 20px;
  }
}

@media (min-width: 1024px) {
  .console-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .console-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .info-item {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .value {
    text-align: left;
    margin-top: 5px;
  }
}
</style>
