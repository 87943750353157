import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-8cef4696"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "static-logo-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["static-logo-container", { 'dark-mode': _ctx.isDarkMode }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.logoLetters, (letter, letterIndex) => {
        return (_openBlock(), _createElementBlock("div", {
          key: letterIndex,
          class: _normalizeClass(['static-letter', { 'small-letter': letter.length === 24 }])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(letter, (pixel, pixelIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              key: pixelIndex,
              class: "static-pixel",
              style: _normalizeStyle(_ctx.pixelStyle(letterIndex, pixelIndex, pixel))
            }, null, 4))
          }), 128))
        ], 2))
      }), 128))
    ]),
    _createElementVNode("div", {
      class: "consulting-text-container",
      style: _normalizeStyle(_ctx.consultingContainerStyle)
    }, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList('CONSULTING', (char, index) => {
        return _createElementVNode("span", {
          key: index,
          class: "consulting-char"
        }, _toDisplayString(char), 1)
      }), 64))
    ], 4)
  ], 2))
}