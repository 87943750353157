<template>
  <div class="auth-container" :class="{ 'dark-mode': isDarkMode }">
    <button @click="$emit('close')" class="close-button" aria-label="Close">
      <i class="fas fa-chevron-down"></i>
    </button>
    <h3>
      {{ isAuthenticated ? $t('authComponent.authenticatedTitle') : (isOtpSent ? $t('authComponent.verifyEmailTitle') : $t('authComponent.loginSignUpTitle')) }}
    </h3>
    <div v-if="isAuthenticated" class="auth-content">
      <p class="logged-in-message">{{ $t('authComponent.loggedInMessage', { username: profile?.username && profile.username.trim() ? profile.username : email }) }}</p>
      <button @click="goToProfile" class="profile-button">
        <i class="fas fa-user-circle"></i>
        {{ $t('authComponent.viewProfileButton') }}
      </button>
      <button @click="handleLogout" class="logout-button">{{ $t('authComponent.logoutButton') }}</button>
    </div>
    <div v-else class="auth-content">
      <form @submit.prevent="handleSubmit">
        <div v-if="!isOtpSent">
          <input
            type="email"
            v-model="email"
            :placeholder="$t('authComponent.emailPlaceholder')"
            required
          />
          <button type="submit" :disabled="isLoading">
            {{ isLoading ? $t('authComponent.sendingButton') : $t('authComponent.verifyButton') }}
          </button>
        </div>
        <div v-else>
          <input
            type="text"
            v-model="otp"
            :placeholder="$t('authComponent.otpPlaceholder')"
            required
            :class="{ 'dark-mode': isDarkMode }"
          />
          <button type="submit" :disabled="isLoading">
            {{ isLoading ? $t('authComponent.verifyingButton') : $t('authComponent.verifyOtpButton') }}
          </button>
          <p
            v-if="canResendOtp" 
            @click.prevent="resendOtp" 
            :disabled="isLoading" 
            class="resend-button"
          >
            {{ $t('authComponent.resendOtpButton') }}
          </p>
          <p v-else-if="isOtpSent" class="resend-timer">
            {{ $t('authComponent.resendOtpTimer', { seconds: resendTimer }) }}
          </p>
        </div>
      </form>
      <p v-if="message" :class="{ 'error': isError, 'success': !isError }">{{ message }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

export default defineComponent({
  name: 'AuthComponent',
  props: {
    isDarkMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  data() {
    return {
      email: '',
      otp: '',
      isOtpSent: false,
      message: '',
      resendTimer: 15,
      canResendOtp: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['authStatus', 'authError', 'isAuthenticated', 'getToken', 'getEmail']),
    ...mapGetters('profile', ['getProfile']),
    isLoading(): boolean {
      return this.authStatus === 'loading';
    },
    isError(): boolean {
      return this.authStatus === 'error';
    },
    profile() {
      return this.getProfile;
    }
  },
  methods: {
    ...mapActions('auth', ['sendVerification', 'verifyCode', 'logout', 'initializeAuth']),
    ...mapActions('profile', ['fetchProfile']),
    async handleSubmit() {
      this.message = '';

      try {
        if (!this.isOtpSent) {
          await this.sendVerification({ email: this.email });
          this.isOtpSent = true;
          this.message = this.$t('authComponent.otpSentMessage');
          this.startResendTimer();
        } else {
          await this.verifyCode({ email: this.email, verificationCode: this.otp });
          this.message = this.$t('authComponent.loginSuccessMessage');
          await this.loadProfile();
        }
      } catch (error) {
        this.message = this.authError || this.$t('authComponent.errorOccurredMessage');
      }
    },
    async handleLogout() {
      await this.logout();
      this.email = '';
      this.otp = '';
      this.isOtpSent = false;
      this.message = this.$t('authComponent.logoutMessage');

      const path = this.$router.currentRoute.value.path;
      if (path === '/profile' || path === '/demo-site' || path === '/diagnostics') {
        await this.$router.push('/');
      }
    },
    goToProfile() {
      this.$router.push('/profile');
    },
    async loadProfile() {
      if (this.isAuthenticated) {
        try {
          await this.fetchProfile();
        } catch (error) {
          console.error('Failed to load profile:', error);
        }
      }
    },
    startResendTimer() {
      this.resendTimer = 15;
      this.canResendOtp = false;
      const timer = setInterval(() => {
        this.resendTimer--;
        if (this.resendTimer <= 0) {
          clearInterval(timer);
          this.canResendOtp = true;
        }
      }, 1500);
    },
    async resendOtp() {
      try {
        await this.sendVerification({ email: this.email });
        this.message = this.$t('authComponent.otpResentMessage');
        this.startResendTimer();
      } catch (error) {
        this.message = this.authError || this.$t('authComponent.errorOccurredMessage');
      }
    }
  },
  async mounted() {
    this.initializeAuth();
    if (this.isAuthenticated) {
      await this.loadProfile();
      if (this.getEmail) {
        this.email = this.getEmail;
      }
    } 
  },
  watch: {
    isAuthenticated(newVal) {
      if (newVal) {
        this.loadProfile();
      }
    },
    getEmail(newEmail) {
      if (newEmail) {
        this.email = newEmail;
      }
    }
  }
});
</script>

<style scoped>
.auth-container {
  position: relative;
  font-family: 'Roboto', Arial, sans-serif;
  padding: 20px;
  padding-top: 40px;
  background-color: #f8f9fa;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
  transition: background-color 0.3s, color 0.3s;
  color: #333;
}

.auth-container.dark-mode {
  background-color: #333;
  color: #f8f9fa;
}

.close-button {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #007bff;
  transition: color 0.3s;
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button i {
  display: block;
  line-height: 1;
  font-size: 1.2em;
}

.close-button:hover {
  color: #0056b3;
}

h3 {
  font-size: 1.5em;
  color: #007bff;
  margin: 10px 0 20px 0;
  text-align: center;
}

.auth-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

input {
  margin-bottom: 15px;
  padding: 12px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  background-color: #f8f9fa;
  color: #333;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
}

.dark-mode input {
  background-color: #222;
  color: #f8f9fa;
  border-color: #555;
}

input:focus {
  outline: none;
  border-color: #007bff;
}

button {
  padding: 12px;
  font-size: 1.1em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s, transform 0.1s;
}

button:hover:not(:disabled) {
  background-color: #0056b3;
}

button:disabled {
  background-color: #555;
  cursor: not-allowed;
}

p {
  margin-top: 15px;
  text-align: center;
  font-size: 1em;
}

.error {
  color: #ff6b6b;
}

.success {
  color: #5dd55d;
}

.logged-in-message {
  font-size: 1.2em;
  text-align: center;
  color: #5dd55d;
  margin-bottom: 15px;
}

.profile-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 1.1em;
  transition: background-color 0.3s, transform 0.1s;
}

.profile-button:hover {
  background-color: #0056b3;
}

.profile-button i {
  margin-right: 10px;
  font-size: 1.2em;
}

.logout-button {
  background-color: #dc3545;
}

.logout-button:hover {
  background-color: #c82333;
}

.resend-button {
  margin-top: 10px;
  color: #28a745;  
  margin-top: 10px;
  font-size: 0.9em;
}

.resend-button:hover {
  color: #218838;
}

.resend-timer {
  margin-top: 10px;
  font-size: 0.9em;
  color: #6c757d;
}

@media (max-width: 480px) {
  .auth-container {
    padding: 15px;
    padding-top: 35px;
  }

  h3 {
    font-size: 1.3em;
    margin-bottom: 6px;
  }

  input, button {
    font-size: 0.9em;
  }
}
</style>