export enum Environment {
    LOCAL = 'LOCAL',
    DEV = 'DEV',
    PROD = 'PROD'
  }
  
  interface AppSettings {
    environment: Environment;
    apiBaseUrl: string;
  }
  
  const getApiBaseUrl = (env: Environment): string => {
    switch (env) {
      case Environment.LOCAL:
        return 'https://localhost:9105'; // Adjust this port if your local API uses a different one
      case Environment.PROD:
        return 'https://dev1api.azurewebsites.net';
      default:
        return 'https://localhost:9105';
    }
  };
  
  // You can change this to Environment.DEV or Environment.LOCAL as needed
  const currentEnvironment = Environment.PROD;
  
  export const settings: AppSettings = {
    environment: currentEnvironment,
    apiBaseUrl: getApiBaseUrl(currentEnvironment)
  };