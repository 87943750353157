import { createRouter, createWebHistory, RouteRecordRaw, RouteLocationNormalized } from 'vue-router'
import Home from '@/components/Home.vue';
import Team from '@/components/Team.vue';
import Contact from '@/components/Contact.vue';
import Profile from '@/components/Profile.vue';
import DemoSite from '@/components/DemoSite.vue';
import Diagnostics from '@/components/Diagnostics.vue';
import ClientDiagnostics from '@/components/ClientDiagnostics.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: (route: RouteLocationNormalized) => ({ isDarkMode: Boolean(route.query.isDarkMode) })
  },
  {
    path: '/meet-the-team',
    name: 'Team',
    component: Team,
    props: (route: RouteLocationNormalized) => ({ isDarkMode: Boolean(route.query.isDarkMode) })
  },
  {
    path: '/contact-us',
    name: 'Contact',
    component: Contact,
    props: (route: RouteLocationNormalized) => ({ isDarkMode: Boolean(route.query.isDarkMode) })
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    props: (route: RouteLocationNormalized) => ({ isDarkMode: Boolean(route.query.isDarkMode) })
  },
  {
    path: '/demo-site',
    name: 'Demo Site',
    component: DemoSite,
    props: (route: RouteLocationNormalized) => ({ isDarkMode: Boolean(route.query.isDarkMode) })
  },
  {
    path: '/diagnostics',
    name: 'Diagnostics',
    component: Diagnostics,
    props: (route: RouteLocationNormalized) => ({ isDarkMode: Boolean(route.query.isDarkMode) })
  },
  {
    path: '/client-diagnostics',
    name: 'Client Diagnostics',
    component: ClientDiagnostics,
    props: (route: RouteLocationNormalized) => ({ isDarkMode: Boolean(route.query.isDarkMode) })
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL || '/'),
  routes,
  scrollBehavior(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    savedPosition: { left: number; top: number } | null
  ) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
});

export default router;