<template>
  <div v-show="isVisible">
    <audio ref="audioPlayer" preload="none" @canplay="onCanPlay" @waiting="onWaiting">
      <source src="https://streaming.tdiradio.com/jazz" type="audio/mpeg">
    </audio>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'JazzPlayer',
  emits: ['mounted', 'loadingStateChange'],
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      status: 'Initialized',
      audioPlayer: null as HTMLAudioElement | null,
      isLoading: false,
    };
  },
  methods: {
    play() {
      if (this.audioPlayer) {
        this.isLoading = true;
        this.$emit('loadingStateChange', true);
        this.audioPlayer.load(); // Force reload of the audio
        this.audioPlayer.play().then(() => {
          this.status = 'Playing';
          this.isLoading = false;
          this.$emit('loadingStateChange', false);
        }).catch(error => {
          this.status = `Error: ${error.message}`;
          this.isLoading = false;
          this.$emit('loadingStateChange', false);
          console.error('Error playing jazz:', error);
        });
      }
    },
    pause() {
      if (this.audioPlayer) {
        this.audioPlayer.pause();
        this.status = 'Paused';
        this.isLoading = false;
        this.$emit('loadingStateChange', false);
      }
    },
    onCanPlay() {
      this.isLoading = false;
      this.$emit('loadingStateChange', false);
    },
    onWaiting() {
      this.isLoading = true;
      this.$emit('loadingStateChange', true);
    }
  },
  mounted() {
    this.audioPlayer = this.$refs.audioPlayer as HTMLAudioElement;
    this.status = 'Ready';
    this.$emit('mounted');
  },
  watch: {
    isVisible(newValue) {
      if (newValue && this.status === 'Paused') {
        this.play();
      } else if (!newValue && this.status === 'Playing') {
        this.pause();
      }
    }
  }
});
</script>