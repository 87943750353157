import { createStore } from 'vuex';
import { contactStore } from './modules/contact.store';
import { RootState } from '@/store/modules/auth.store';
import { authStore } from './modules/auth.store';
import { profileStore } from './modules/profile.store';
import { diagnosticsStore } from './modules/diagnostics.store';

export default createStore<RootState>({
  modules: {
    contact: contactStore,
    auth: authStore,
    profile: profileStore,
    diagnostics: diagnosticsStore
  }
});