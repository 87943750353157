import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-db7c9f1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hero" }
const _hoisted_2 = { class: "overview" }
const _hoisted_3 = { class: "architecture" }
const _hoisted_4 = { class: "architecture-cards" }
const _hoisted_5 = {
  href: "https://dev1api.azurewebsites.net/",
  target: "_blank",
  class: "swagger-link"
}
const _hoisted_6 = { class: "tech-stack" }
const _hoisted_7 = { class: "tech-stack-grid" }
const _hoisted_8 = { class: "contact" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['demo-site-container', { 'dark-mode': _ctx.isDarkMode }])
  }, [
    _createElementVNode("section", _hoisted_1, [
      _createVNode(_component_Logo, { isDarkMode: _ctx.isDarkMode }, null, 8, ["isDarkMode"]),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('demoSite.heroText')), 1)
    ]),
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('demoSite.overviewTitle')), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('demoSite.overviewContent')), 1)
    ]),
    _createElementVNode("section", _hoisted_3, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('demoSite.architectureTitle')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: _normalizeClass(['architecture-card', { 'dark-mode-card': _ctx.isDarkMode }])
        }, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('demoSite.vueAppTitle')), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('demoSite.vueAppContent')), 1)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(['architecture-card', { 'dark-mode-card': _ctx.isDarkMode }])
        }, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('demoSite.apiTitle')), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('demoSite.apiContent')), 1),
          _createElementVNode("p", null, [
            _createElementVNode("a", _hoisted_5, _toDisplayString(_ctx.$t('demoSite.apiSwaggerLink')), 1)
          ])
        ], 2)
      ])
    ]),
    _createElementVNode("section", _hoisted_6, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('demoSite.techStackTitle')), 1),
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$tm('demoSite.techStackItems'), (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "tech-stack-item",
            key: index
          }, _toDisplayString(item), 1))
        }), 128))
      ])
    ]),
    _createElementVNode("section", _hoisted_8, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('demoSite.contactTitle')), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('demoSite.contactContent')), 1),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.navigateToContact && _ctx.navigateToContact(...args)))
      }, _toDisplayString(_ctx.$t('demoSite.contactButton')), 1)
    ])
  ], 2))
}