<template>
  <div :class="['contact-container', { 'dark-mode': isDarkMode }]">
    <div :class="['card', 'contact-form', { 'dark-mode-card': isDarkMode }]">
      <StaticLogo :isDarkMode="isDarkMode" :basePixelSize="8" />
      <h2>{{ $t('contact.form.title') }}</h2>
      <p>{{ $t('contact.form.intro') }}</p>
      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <label for="email">{{ $t('contact.form.emailLabel') }}</label>
          <input 
            type="email" 
            id="email" 
            v-model="email" 
            required 
            :class="{ 'dark-mode-input': isDarkMode, 'error': !isValidEmail && isSubmitted }"
          >
          <span class="error-message" v-if="!isValidEmail && isSubmitted">{{ $t('contact.form.emailError') }}</span>
        </div>
        <div class="form-group">
          <label for="message">{{ $t('contact.form.messageLabel') }}</label>
          <textarea 
            id="message" 
            v-model="message" 
            required 
            :class="{ 'dark-mode-input': isDarkMode, 'error': !isValidMessage && isSubmitted }"
          ></textarea>
          <span class="error-message" v-if="!isValidMessage && isSubmitted">{{ $t('contact.form.messageError') }}</span>
        </div>
        <div class="form-group">
          <div ref="recaptcha"></div>
          <span class="error-message" v-if="!isValidCaptcha && isSubmitted">{{ $t('contact.form.captchaError') }}</span>
        </div>
        <button type="submit" :disabled="isSubmitting || !isValidForm">
          {{ isSubmitting ? $t('contact.form.sendingButton') : $t('contact.form.sendButton') }}
        </button>
      </form>
      <p v-if="submissionError" class="error-message">{{ $t('contact.form.submissionError') }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { ContactRequest } from '@/models/contact.model';
import StaticLogo from '@/components/StaticLogo.vue';

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Contact',
  components: {
    StaticLogo
  },
  props: {
    isDarkMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      email: '',
      message: '',
      recaptchaResponse: '',
      recaptchaLoaded: false,
      isSubmitted: false
    };
  },
  computed: {
    ...mapGetters('contact', ['isSubmitting', 'submissionError']),
    isValidEmail(): boolean {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(this.email);
    },
    isValidMessage(): boolean {
      return this.message.trim().length > 0;
    },
    isValidCaptcha(): boolean {
      return this.recaptchaResponse.length > 0;
    },
    isValidForm(): boolean {
      return this.isValidEmail && this.isValidMessage && this.isValidCaptcha;
    }
  },
  methods: {
    ...mapActions('contact', ['submitContactForm']),
    loadReCaptchaScript() {
      if (!document.querySelector('#recaptcha-script')) {
        const script = document.createElement('script');
        script.id = 'recaptcha-script';
        script.src = 'https://www.google.com/recaptcha/api.js?render=explicit';
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
        script.onload = () => {
          this.renderReCaptcha();
        };
      } else {
        this.renderReCaptcha();
      }
    },
    renderReCaptcha() {
      if (this.$refs.recaptcha && window.grecaptcha && window.grecaptcha.render) {
        try {
          window.grecaptcha.render(this.$refs.recaptcha as HTMLElement, {
            'sitekey': '6LfBTgoqAAAAAN40HSe5fVGCduWIk3NHjyr1SSQN',
            'callback': this.onCaptchaVerified
          });
          this.recaptchaLoaded = true;
        } catch (error) {
          console.error('Failed to render reCAPTCHA:', error);
          setTimeout(() => this.renderReCaptcha(), 100);
        }
      } else {
        setTimeout(() => this.renderReCaptcha(), 100);
      }
    },
    onCaptchaVerified(response: string) {
      this.recaptchaResponse = response;
    },
    async handleSubmit() {
      this.isSubmitted = true;

      if (!this.isValidForm) {
        return;
      }

      const payload: ContactRequest = {
        email: this.email,
        message: this.message,
        recaptchaToken: this.recaptchaResponse
      };

      try {
        await this.submitContactForm(payload);
        this.email = '';
        this.message = '';
        this.recaptchaResponse = '';
        this.isSubmitted = false;
        if (window.grecaptcha && window.grecaptcha.reset) {
          window.grecaptcha.reset();
        }
        alert(this.$t('contact.form.submissionSuccess'));
      } catch (error) {
        console.error('Error submitting form:', error);
        this.submissionError = this.$t('contact.form.submissionError');
      }
    }
  },
  mounted() {
    this.loadReCaptchaScript();
  }
});
</script>

<style scoped>
.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  font-family: 'Roboto', Arial, sans-serif;
  transition: background-color 0.3s, color 0.3s;
  user-select: none;
  max-width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
}

.contact-container.dark-mode {
  background-color: #222;
  color: #f8f9fa;
}

.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 25px;
  margin-bottom: 40px;
  width: 100%;
  max-width: 800px;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-sizing: border-box;
}

.card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.contact-container.dark-mode .card {
  background-color: #333;
  color: #f8f9fa;
}

h2 {
  color: #007bff;
  font-size: 1.6rem;
  margin-bottom: 1.25rem;
  border-bottom: 2px solid #007bff;
  padding-bottom: 0.5rem;
}

p {
  font-size: 1.1em;
  line-height: 1.5;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input, textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  box-sizing: border-box;
}

textarea {
  height: 150px;
  resize: vertical;
}

.contact-container.dark-mode input,
.contact-container.dark-mode textarea {
  background-color: #444;
  color: #f8f9fa;
  border-color: #555;
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  max-width: 200px;
}

button:hover:not(:disabled) {
  background-color: #0056b3;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.error {
  border-color: #dc3545 !important;
}

.error-message {
  color: #dc3545;
  font-size: 0.875em;
  margin-top: 0.25rem;
}

@media (max-width: 768px) {
  .contact-container {
    padding: 1.25rem;
  }

  .card {
    padding: 1rem;
  }

  h2 {
    font-size: 1.4rem;
  }

  p {
    font-size: 1em;
  }

  button {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .contact-container {
    padding: 0.6rem;
  }

  .card {
    padding: 0.75rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  p {
    font-size: 0.9em;
  }

  input, textarea {
    font-size: 0.9em;
  }

  button {
    font-size: 0.9rem;
    padding: 0.6rem 1.2rem;
  }
}
</style>
