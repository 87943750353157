<template>
  <div :class="['profile-container', { 'dark-mode': isDarkMode }]">
    <div class="profile-content">
      <h1>{{ $t('profile.title') }}</h1>
      <div :class="['card', 'profile-form', { 'dark-mode-card': isDarkMode }]">
        <div v-if="isLoading" class="spinner-container">
          <div class="spinner"></div>
        </div>
        <div v-else>
          <div class="email-display">
            <label>{{ $t('profile.emailLabel') }}</label>
            <span>{{ email }}</span>
          </div>
          <form @submit.prevent="saveProfile">
            <div class="form-group">
              <label for="username">{{ $t('profile.usernameLabel') }}</label>
              <input 
                type="text" 
                id="username" 
                v-model="localProfile.username" 
                maxlength="16" 
                @blur="checkUsername"
              >
              <span class="validation-message" v-if="showUsernameLengthError">
                {{ $t('profile.usernameLengthError') }}
              </span>
              <span class="validation-message" v-if="showUsernameExistsError">
                {{ $t('profile.usernameExistsError') }}
              </span>
            </div>
            <div class="form-group">
              <label for="phone">{{ $t('profile.phoneLabel') }}</label>
              <input 
                type="tel" 
                id="phone" 
                v-model="localProfile.phoneNumber"
              >
              <span class="validation-message" v-if="localProfile.phoneNumber && !isPhoneValid">
                {{ $t('profile.phoneInvalidError') }}
              </span>
            </div>
            <div class="form-group">
              <label for="siteUrl">{{ $t('profile.siteUrlLabel') }}</label>
              <div class="input-group">
                <span class="input-group-addon">https://</span>
                <input 
                  type="text" 
                  id="siteUrl" 
                  v-model="siteUrlWithoutProtocol"
                >
              </div>
              <span class="validation-message" v-if="localProfile.siteUrl && !isUrlValid">
                {{ $t('profile.urlInvalidError') }}
              </span>
            </div>
            <button 
              type="submit" 
              :disabled="!isFormValid || isSaving || !isFormChanged"
            >
              <span class="button-text">
                {{ isSaving ? $t('profile.savingButton') : $t('profile.saveButton') }}
              </span>
              <span class="button-icon" v-if="!isSaving">
                &#10003;
              </span>
            </button>
          </form>
        </div>
      </div>
      <transition name="fade">
        <p v-if="message" :class="['message', { 'success': !isError, 'error': isError }]">
          {{ message }}
        </p>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import { Profile } from '@/store/modules/profile.store';

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Profile',
  props: {
    isDarkMode: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  data() {
    return {
      localProfile: {
        username: '',
        phoneNumber: '',
        siteUrl: ''
      } as Profile,
      originalProfile: null as Profile | null,
      originalUsername: '',
      message: '',
      isError: false,
      isSaving: false,
      isLoading: true, // Initialize loading state
      usernameExists: false
    };
  },
  computed: {
    ...mapState('profile', ['profile']),
    ...mapGetters('auth', ['getEmail']),
    email(): string {
      return this.getEmail || '';
    },
    siteUrlWithoutProtocol: {
      get() {
        return this.localProfile.siteUrl.replace(/^https?:\/\//, '');
      },
      set(value: string) {
        this.localProfile.siteUrl = value ? `https://${value}` : '';
      }
    },
    showUsernameLengthError(): boolean {
      return this.localProfile.username?.length > 0 && (this.localProfile.username?.length < 2 || this.localProfile.username?.length > 16);
    },
    showUsernameExistsError(): boolean {
      return this.usernameExists && this.localProfile.username !== this.originalUsername;
    },
    isPhoneValid(): boolean {
      if (!this.localProfile.phoneNumber) return true;
      const phoneRegex = /^\+?[\d\s-]{10,15}$/;
      return phoneRegex.test(this.localProfile.phoneNumber);
    },
    isUrlValid(): boolean {
      if (!this.localProfile.siteUrl) return true;
      const urlRegex = new RegExp('^(https://)?([\\da-z.-]+)\\.([a-z.]{2,6})([/\\w .-]*)*/?$');
      return urlRegex.test(this.localProfile.siteUrl);
    },
    isFormValid(): boolean {
      return (
        (!this.localProfile.username || (this.localProfile.username?.length >= 2 && this.localProfile.username?.length <= 16 && (!this.usernameExists || this.localProfile.username === this.originalUsername))) &&
        (!this.localProfile.phoneNumber || this.isPhoneValid) &&
        (!this.localProfile.siteUrl || this.isUrlValid)
      );
    },
    isFormChanged(): boolean {
      if (!this.originalProfile) return false;
      return (
        this.localProfile.username !== this.originalProfile.username ||
        this.localProfile.phoneNumber !== this.originalProfile.phoneNumber ||
        this.localProfile.siteUrl !== this.originalProfile.siteUrl
      );
    }
  },
  methods: {
    ...mapActions('profile', ['fetchProfile', 'updateProfile', 'checkUsernameExists']),
    async loadProfile() {
      try {
        const response = await this.fetchProfile();
        if (response && response.success) {
          const profileData = response.data;
          this.localProfile = {
            username: profileData.username || '',
            phoneNumber: profileData.phoneNumber || '',
            siteUrl: profileData.siteUrl || ''
          };
          this.originalProfile = { ...this.localProfile };
          this.originalUsername = this.localProfile.username;
        }
      } catch (error) {
        console.error('Failed to load profile:', error);
        this.message = 'Failed to load profile. Please try again.';
        this.isError = true;
      } finally {
        this.isLoading = false;
        setTimeout(() => {
          this.message = '';
          this.isError = false;
        }, 2000);
      }
    },
    async saveProfile() {
      if (!this.isFormValid || !this.isFormChanged) return;

      this.isSaving = true;
      this.message = '';
      this.isError = false;

      try {
        await this.updateProfile(this.localProfile);
        this.message = 'Profile updated successfully!';
        this.originalProfile = { ...this.localProfile };
        this.originalUsername = this.localProfile.username;
      } catch (error) {
        this.message = 'Failed to update profile. Please try again.';
        this.isError = true;
      } finally {
        this.isSaving = false;
        setTimeout(() => {
          this.message = '';
          this.isError = false;
        }, 2000);
      }
    },
    async checkUsername() {
      if (this.localProfile.username?.length >= 2 && this.localProfile.username?.length <= 16 && this.localProfile.username !== this.originalUsername) {
        try {
          this.usernameExists = await this.checkUsernameExists(this.localProfile.username);
        } catch (error) {
          console.error('Failed to check username:', error);
        }
      }
    }
  },
  async mounted() {
    await this.loadProfile();
  },
  watch: {
    profile: {
      handler(newProfile: Profile) {
        if (newProfile) {
          this.localProfile = { ...newProfile };
          this.originalProfile = { ...newProfile };
          this.originalUsername = newProfile.username;
        }
      },
      immediate: true,
      deep: true
    },
    getEmail: {
      handler(newEmail) {
        if (newEmail) {
          this.email = newEmail;
        }
      },
      immediate: true
    }
  }
});
</script>

<style scoped>
.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 40px);
  padding: 20px;
  font-family: 'Roboto', Arial, sans-serif;
  transition: background-color 0.3s, color 0.3s;
  user-select: none;
}

.profile-container.dark-mode {
  background-color: #222;
  color: #f8f9fa;
}

.profile-content {
  width: 100%;
  max-width: 600px;
}

h1 {
  font-size: 2.5em;
  margin-bottom: 30px;
  color: #007bff;
  text-align: center;
}

.card {
  background-color: #f9f9f9;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 1 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.card:hover {
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
}

.profile-container.dark-mode .card {
  background-color: #333;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust as necessary */
}

.spinner {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #007bff; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.form-group {
  margin-bottom: 25px;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #007bff;
  transition: color 0.3s;
}

.profile-container.dark-mode label {
  color: #4da3ff;
}

.input-group {
  display: flex;
}

.input-group-addon {
  background-color: #ddd;
  border: 2px solid #ddd;
  border-right: none;
  border-radius: 8px 0 0 8px;
  padding: 12px;
  font-size: 1em;
  color: #555;
  display: flex;
  align-items: center;
}

.profile-container.dark-mode .input-group-addon {
  background-color: #555;
  border-color: #555;
  color: #f8f9fa;
}

input {
  width: 100%;
  padding: 12px;
  font-size: 1em;
  border: 2px solid #ddd;
  border-radius: 8px;
  transition: border-color 0.3s, box-shadow 0.3s;
  box-sizing: border-box;
}

.profile-container.dark-mode input {
  background-color: #444;
  color: #f8f9fa;
  border-color: #555;
}

input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 14px 24px;
  font-size: 1.1em;
  font-weight: bold;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
}

button:hover:not(:disabled) {
  background-color: #0056b3;
  transform: translateY(-2px);
}

button:active:not(:disabled) {
  transform: translateY(0);
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.button-text {
  margin-right: 10px;
}

.button-icon {
  font-size: 1.2em;
}

.message {
  margin-top: 20px;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  font-weight: bold;
  transition: opacity 0.3s, transform 0.3s;
}

.success {
  background-color: #d4edda;
  color: #155724;
}

.error {
  background-color: #f8d7da;
  color: #721c24;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.validation-message {
  color: #dc3545;
  font-size: 0.9em;
  margin-top: 5px;
  display: block;
}

.profile-container.dark-mode .validation-message {
  color: #ff6b6b;
}

.email-display {
  margin-bottom: 20px;
  text-align: center;
}

.email-display label {
  font-weight: bold;
  margin-right: 10px;
}

.email-display span {
  font-style: italic;
}

@media (max-width: 768px) {
  .profile-container {
    padding: 15px;
  }

  h1 {
    font-size: 2.2em;
  }

  .card {
    padding: 30px;
  }
}

@media (max-width: 480px) {
  .profile-container {
    padding: 10px;
  }

  h1 {
    font-size: 2em;
  }

  .card {
    padding: 20px;
  }

  input, button {
    font-size: 0.9em;
  }
}
</style>
