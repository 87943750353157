import { Module, ActionContext } from 'vuex';
import { RootState } from '@/store/modules/auth.store';
import { updateProfile, fetchProfile, checkUsernameExists } from '@/services/api.service';

export interface ProfileState {
  status: 'idle' | 'loading' | 'success' | 'error';
  profile: Profile | null;
  error: string | null;
  usernameExists: boolean | null;
}

export interface Profile {
  username: string;
  phoneNumber: string;
  siteUrl: string;
}

export interface UpdateProfileResponse {
  success: boolean;
  message: string | null;
}

export const profileStore: Module<ProfileState, RootState> = {
  namespaced: true,
  state: {
    status: 'idle',
    profile: null,
    error: null,
    usernameExists: null,
  },
  mutations: {
    setStatus(state: ProfileState, status: 'idle' | 'loading' | 'success' | 'error') {
      state.status = status;
    },
    setProfile(state: ProfileState, profile: Profile | null) {
      state.profile = profile;
    },
    setError(state: ProfileState, error: string | null) {
      state.error = error;
    },
    setUsernameExists(state: ProfileState, exists: boolean) {
      state.usernameExists = exists;
    }
  },
  actions: {
    async updateProfile(
      { commit }: ActionContext<ProfileState, RootState>,
      payload: Profile
    ) {
      commit('setStatus', 'loading');
      try {
        const response = await updateProfile(payload);
        if(response.success){
          commit('setStatus', 'success');
        } else {
          commit('setStatus', 'error');
          commit('setError', 'An unknown error occurred');
          throw new Error('An unknown error occurred');
        }
      } catch (error) {
        commit('setStatus', 'error');
        commit('setError', error instanceof Error ? error.message : 'An unknown error occurred');
        throw error;
      }
    },
    async fetchProfile({ commit }: ActionContext<ProfileState, RootState>) {
      commit('setStatus', 'loading');
      try {
        const response = await fetchProfile();
        if (response) {
          commit('setProfile', response.data);
        }
        commit('setStatus', 'success');
      } catch (error) {
        commit('setStatus', 'error');
        commit('setError', error instanceof Error ? error.message : 'An unknown error occurred');
        throw error;
      }
    },
    async checkUsernameExists(
      { commit }: ActionContext<ProfileState, RootState>,
      username: string
    ) {
      commit('setStatus', 'loading');
      try {
        const exists = await checkUsernameExists(username);
        commit('setUsernameExists', exists);
        commit('setStatus', 'success');
      } catch (error) {
        commit('setStatus', 'error');
        commit('setError', error instanceof Error ? error.message : 'An unknown error occurred');
        throw error;
      }
    },
    clearProfile({ commit }: ActionContext<ProfileState, RootState>) {
      commit('setProfile', null);
      commit('setStatus', 'idle');
      commit('setError', null);
    },
  },
  getters: {
    profileStatus: (state: ProfileState) => state.status,
    profileError: (state: ProfileState) => state.error,
    getProfile: (state: ProfileState) => state.profile,
    usernameExists: (state: ProfileState) => state.usernameExists
  },
};