<template>
  <div class="static-logo-container" :class="{ 'dark-mode': isDarkMode }">
    <div class="static-logo-text">
      <div v-for="(letter, letterIndex) in logoLetters" :key="letterIndex" :class="['static-letter', { 'small-letter': letter.length === 24 }]">
        <div
          v-for="(pixel, pixelIndex) in letter"
          :key="pixelIndex"
          class="static-pixel"
          :style="pixelStyle(letterIndex, pixelIndex, pixel)"
        ></div>
      </div>
    </div>
    <div class="consulting-text-container" :style="consultingContainerStyle">
      <span v-for="(char, index) in 'CONSULTING'" :key="index" class="consulting-char">{{ char }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StaticLogo',
  props: {
    isDarkMode: {
      type: Boolean,
      default: false
    },
    basePixelSize: {
      type: Number,
      default: 4
    }
  },
  data() {
    return {
      logoLetters: [
        // D
        [1,1,1,0,0,1,0,0,1,0,1,0,0,1,0,1,0,0,1,0,1,0,0,1,0,1,1,1,0],
        // E
        [1,1,1,1,0,1,0,0,0,0,1,1,1,0,0,1,0,0,0,0,1,0,0,0,0,1,1,1,1],
        // V
        [1,0,0,0,1,1,0,0,0,1,1,0,0,0,1,0,1,0,1,0,0,1,0,1,0,0,0,1],
        // 1
        [0,0,1,0,0,1,1,0, 0,0,1,0,0,0,1,0,0,0,1,0,0,1,1,1]
      ]
    };
  },
  computed: {
    pixelSize(): string {
      return `${this.basePixelSize}px`;
    },
    logoWidth(): number {
      return this.logoLetters.length * (this.basePixelSize * 5 + 6); // 5 pixels per letter + 3 gaps
    },
    consultingContainerStyle(): object {
      return {
        width: `${this.logoWidth}px`,
        height: `${this.basePixelSize * 4}px`,
        textAlign: 'center',
      };
    }
  },
  methods: {
    pixelStyle(letterIndex: number, pixelIndex: number, isOn: number) {
      if (!isOn) return { opacity: 0 };
      return {
        backgroundColor: this.getPixelColor(letterIndex, pixelIndex),
        width: this.pixelSize,
        height: this.pixelSize
      };
    },
    getPixelColor(letterIndex: number, pixelIndex: number) {
      const blueHue = 220;
      const baseLightness = this.isDarkMode ? 60 : 40;
      const lightnessVariation = (letterIndex * 5 + pixelIndex) % 20 - 10;
      const lightness = Math.max(0, Math.min(100, baseLightness + lightnessVariation));
      return `hsl(${blueHue}, 70%, ${lightness}%)`;
    }
  }
});
</script>

<style scoped>
.static-logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 0;
}

.static-logo-text {
  display: flex;
  gap: 3px;
}

.static-letter {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1px;
}

.static-letter.small-letter {
  grid-template-columns: repeat(4, 1fr); /* Adjusted to fit 4 columns for "1" */
}

.static-pixel {
  border-radius: 1px;
}

.consulting-text-container {
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.consulting-char {
  font-family: 'Font Awesome 5 Free', monospace;
  font-weight: 400;
  color: hsl(220, 68%, 41%);
  text-transform: uppercase;
  -webkit-font-smoothing: none;
  -moz-osx-font-smoothing: grayscale;
  flex: 1; /* Each character will take an equal amount of space */
  text-align: center; /* Center the text inside each flex item */
  font-size: 10px;
}

.dark-mode .static-pixel {
  filter: brightness(1.2);
}

.dark-mode .consulting-char {
  color: hsl(220, 67%, 62%);
}
</style>
