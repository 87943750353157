<template>
  <nav class="top-nav" :class="{ 'dark-mode': isDarkMode }">
    <div class="top-nav-content">
      <div class="top-nav-right">
        <div class="auth-dropdown" :class="{ 'open': isAuthDropdownOpen }" ref="authDropdown">
          <a href="#" class="auth-link special-auth-link" @click.prevent="toggleAuthDropdown">
            {{ isAuthenticated ? $t('sidebar.showAccount') : $t('sidebar.createAccountLogin') }}
            <i class="fas fa-sign-in-alt"></i>
          </a>
          <div class="auth-dropdown-content">
            <AuthComponent 
              :isDarkMode="isDarkMode" 
              @close="closeAuthDropdown" 
            />
          </div>
        </div>
        <div class="language-switcher" ref="languageDropdown">
          <div class="dropdown" :class="{ 'open': isDropdownOpen }">
            <button @click="toggleDropdown" :class="['dropbtn', { 'dark-mode': isDarkMode }]">
              <img :src="getSelectedLanguageFlag()" :alt="selectedLanguage" />
              {{ selectedLanguage }}
              <i class="fas fa-chevron-down"></i>
            </button>
            <div class="dropdown-content">
              <a v-for="lang in availableLanguages" :key="lang.code" @click="changeLanguage(lang.code)">
                <img :src="lang.flag" :alt="lang.name" />
                {{ lang.name }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import AuthComponent from '@/components/AuthComponent.vue';

export default defineComponent({
  name: 'TopNav',
  components: {
    AuthComponent,
  },
  props: {
    isDarkMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDropdownOpen: false,
      isAuthDropdownOpen: false,
      selectedLanguage: 'English',
      availableLanguages: [
        { code: 'en', name: 'English', flag: require('@/assets/us-flag.svg') },
        { code: 'ru', name: 'Русский', flag: require('@/assets/ru-flag.svg') },   
        { code: 'cn', name: '中文', flag: require('@/assets/cn-flag.svg') },
        { code: 'th', name: 'ไทย', flag: require('@/assets/th-flag.svg') },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
  },
  methods: {
    toggleAuthDropdown() {
      this.isAuthDropdownOpen = !this.isAuthDropdownOpen;
      if (this.isAuthDropdownOpen) {
        this.isDropdownOpen = false;
      }
    },
    closeAuthDropdown() {
      this.isAuthDropdownOpen = false;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      if (this.isDropdownOpen) {
        this.isAuthDropdownOpen = false;
      }
    },
    changeLanguage(lang: string) {
      this.$i18n.locale = lang;
      this.selectedLanguage = this.availableLanguages.find(l => l.code === lang)?.name || 'English';
      this.isDropdownOpen = false;
      this.$emit('languageChanged', lang);
    },
    getSelectedLanguageFlag() {
      return this.availableLanguages.find(l => l.name === this.selectedLanguage)?.flag || this.availableLanguages[0].flag;
    },
    handleClickOutside(event: MouseEvent) {
      const authDropdown = this.$refs.authDropdown as HTMLElement;
      const languageDropdown = this.$refs.languageDropdown as HTMLElement;

      if (authDropdown && !authDropdown.contains(event.target as Node)) {
        this.closeAuthDropdown();
      }

      if (languageDropdown && !languageDropdown.contains(event.target as Node)) {
        this.isDropdownOpen = false;
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
});
</script>

<style scoped>
.top-nav {
  background-color: #ffffff;
  padding: 20px 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: background-color 0.3s ease;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
  user-select: none;
}

.top-nav.dark-mode {
  background-color: #333;
  color: #f8f9fa;
}

.top-nav-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
  padding-right: 20px;
}

.top-nav-right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.auth-link {
  color: #333;
  text-decoration: none;
  font-size: 16px;
  margin-right: 20px;
  transition: color 0.3s;
}

.top-nav.dark-mode .auth-link {
  color: #f8f9fa;
}

.special-auth-link {
  background-color: #28a745;
  color: #ffffff !important;
  border-radius: 5px;
  padding: 8px 16px;
  transition: background-color 0.3s, transform 0.3s;
}

.special-auth-link:hover {
  background-color: #208538 !important;
  transform: translateY(-2px);
}

.language-switcher {
  position: relative;
  cursor: pointer;
}

.dropbtn {
  background-color: #f9f9f9;
  color: #333;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropbtn.dark-mode {
  background-color: #222;
  color: #f8f9fa;
}

.dropbtn img {
  width: 20px;
  height: 14px;
  margin-right: 10px;
}

.dropdown-content, .auth-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 5px;
  right: 0;
}

.top-nav.dark-mode .dropdown-content,
.top-nav.dark-mode .auth-dropdown-content {
  background-color: #333;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.top-nav.dark-mode .dropdown-content a {
  color: #f8f9fa;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.top-nav.dark-mode .dropdown-content a:hover {
  background-color: #555;
}

.dropdown-content img {
  width: 20px;
  height: 14px;
  margin-right: 10px;
}

.dropdown.open .dropdown-content,
.auth-dropdown.open .auth-dropdown-content {
  display: block;
}

.fa-chevron-down {
  margin-left: 5px;
}

.auth-dropdown {
  position: relative;
}

.auth-dropdown-content {
  top: 100%;
  right: 0;
  margin-top: 10px;
  padding: 10px;
  width: 300px;
}

@media (max-width: 1100px) {
  .top-nav {
    display: none;
  }
}
</style>