import { Module, ActionContext } from 'vuex';
import { ContactState, ContactRequest } from '@/models/contact.model';
import { submitContact } from '@/services/api.service';
import { RootState } from '@/store/modules/auth.store';

export const contactStore: Module<ContactState, RootState> = {
  namespaced: true,
  state: {
    submissionStatus: 'idle',
    submissionError: null,
  },
  mutations: {
    setSubmissionStatus(state: ContactState, status: 'idle' | 'submitting' | 'success' | 'error') {
      state.submissionStatus = status;
    },
    setSubmissionError(state: ContactState, error: string | null) {
      state.submissionError = error;
    },
  },
  actions: {
    async submitContactForm(
      { commit }: ActionContext<ContactState, RootState>,
      payload: ContactRequest
    ) {
      commit('setSubmissionStatus', 'submitting');
      try {
        const response = await submitContact(payload);
        commit('setSubmissionStatus', 'success');
        return response;
      } catch (error) {
        commit('setSubmissionStatus', 'error');
        commit('setSubmissionError', error instanceof Error ? error.message : 'An unknown error occurred');
        throw error;
      }
    },
  },
  getters: {
    isSubmitting: (state: ContactState) => state.submissionStatus === 'submitting',
    submissionError: (state: ContactState) => state.submissionError,
  },
};