import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6538fb3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "controls-container" }
const _hoisted_2 = { class: "button-container" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "color-selector" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "drawing-wrapper",
    tabindex: "0",
    onKeydown: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.handleKeyDown && _ctx.handleKeyDown(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleActiveDrawing && _ctx.toggleActiveDrawing(...args))),
          class: _normalizeClass([{ active: _ctx.isActivelyDrawing }, "control-button pencil-button"])
        }, " ✏️ " + _toDisplayString(_ctx.isActivelyDrawing ? 'Stop Drawing' : 'Start Drawing'), 3),
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.undo && _ctx.undo(...args))),
          class: "control-button undo-button",
          disabled: !_ctx.canUndo
        }, "↩️ Undo", 8, _hoisted_3)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colors, (color) => {
          return (_openBlock(), _createElementBlock("button", {
            key: color,
            onClick: ($event: any) => (_ctx.selectColor(color)),
            class: _normalizeClass(['color-button', { active: _ctx.currentColor === color }]),
            style: _normalizeStyle({ backgroundColor: color })
          }, null, 14, _hoisted_5))
        }), 128))
      ])
    ]),
    _createElementVNode("canvas", {
      ref: "drawingCanvas",
      class: _normalizeClass({ 'active-canvas': _ctx.isActivelyDrawing }),
      style: _normalizeStyle({ pointerEvents: _ctx.pointerEvents }),
      onMousedown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.startDrawing && _ctx.startDrawing(...args))),
      onMousemove: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.draw && _ctx.draw(...args))),
      onMouseup: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.stopDrawing && _ctx.stopDrawing(...args))),
      onMouseout: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.stopDrawing && _ctx.stopDrawing(...args))),
      onTouchstart: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleTouchStart && _ctx.handleTouchStart(...args))),
      onTouchmove: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.handleTouchMove && _ctx.handleTouchMove(...args))),
      onTouchend: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.handleTouchEnd && _ctx.handleTouchEnd(...args)))
    }, null, 38)
  ], 32))
}