<template>
  <div :class="['home-container', { 'dark-mode': isDarkMode }]">
    <section class="hero">      
      <Logo :isDarkMode="isDarkMode" />
      <p>{{ $t('home.hero.tagline') }}</p>
      <button @click="navigateToContact">{{ $t('home.hero.cta') }}</button>
    </section>

    <section class="about">
      <h2>{{ $t('home.about.title') }}</h2>
      <p>{{ $t('home.about.content') }}</p>
    </section>

    <section class="services">
      <h2>{{ $t('home.services.title') }}</h2>
      <div class="service-cards">
        <div v-for="(service, index) in $tm('home.services.items')" :key="index" :class="['service-card', { 'dark-mode-card': isDarkMode }]">
          <h3>{{ $t(`home.services.items[${index}].title`) }}</h3>
          <p>{{ $t(`home.services.items[${index}].description`) }}</p>
        </div>
      </div>
    </section>

    <section class="expertise">
      <h2>{{ $t('home.expertise.title') }}</h2>
      <div class="expertise-grid">
        <div v-for="(item, index) in $tm('home.expertise.items')" :key="index" class="expertise-item">
          {{ item }}
        </div>
      </div>
    </section>

    <section class="portfolio">
      <h2>{{ $t('home.portfolio.title') }}</h2>
      <div class="project-cards">
        <div v-for="(project, index) in $tm('home.portfolio.projects')" :key="index" :class="['project-card', { 'dark-mode-card': isDarkMode }]">
          <h3>{{ project.title }}</h3>
          <p>{{ project.description }}</p>
        </div>
      </div>
    </section>

    <section class="contact">
      <h2>{{ $t('home.contact.title') }}</h2>
      <p>{{ $t('home.contact.content') }}</p>
      <button @click="navigateToContact">{{ $tm('home.contact.cta') }}</button>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Logo from '@/components/Logo.vue';

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",
  components: {
    Logo,
  },
  props: {
    isDarkMode: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    navigateToContact() {
      this.$router.push("/contact-us");
    },
  }
});
</script>

<style scoped>
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto', Arial, sans-serif;
  padding: 20px;
  transition: background-color 0.3s, color 0.3s;  
  user-select: none;
  max-width: 100%;
  overflow-x: hidden;
}

.home-container.dark-mode {
  background-color: #222;
  color: #f8f9fa;
}

.hero {
  text-align: center;
  padding: 25px;
  background-color: #f4f4f4;
  margin-bottom: 40px;
  width: 100%;
  max-width: 800px;
  border-radius: 10px;
  box-sizing: border-box;
}

.home-container.dark-mode .hero {
  background-color: #333;
}

.hero h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #007bff;
}

.hero p {
  font-size: 1.2em;
  margin-bottom: 30px;
}

button {
  padding: 12px 24px;
  font-size: 1.1em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
  transition: background-color 0.3s, transform 0.3s;
  transform: translateY(-5px);
}

.about, .services, .expertise, .portfolio, .contact {
  margin-bottom: 60px;
  width: 100%;
  max-width: 800px;
  padding: 0 20px;
  box-sizing: border-box;
}

h2 {
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #007bff;
}

p {
  font-size: 1.1em;
  line-height: 1.6;
}

.service-cards, .project-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.service-card, .project-card {
  background-color: #f9f9f9;
  padding: 20px;
  width: 100%;
  max-width: 350px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  box-sizing: border-box;
}

.home-container.dark-mode .service-card,
.home-container.dark-mode .project-card {
  background-color: #333;
}

.service-card h3, .project-card h3 {
  font-size: 1.3em;
  margin-bottom: 15px;
  color: #007bff;
}

.expertise-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
}

.expertise-item {
  color: #007bff;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  font-weight: bold;
  transition: transform 0.3s;
  text-align: center;
  border: 1px solid #007bff;
  background-color: transparent;
}

.home-container.dark-mode .expertise-item {
  color: #4da3ff;
  border-color: #4da3ff;
}

@media (max-width: 768px) {
  .hero {
    padding: 60px 20px;
  }

  .hero h1 {
    font-size: 2em;
  }

  .hero p {
    font-size: 1.1em;
  }

  h2 {
    font-size: 1.8em;
  }

  .service-card, .project-card {
    max-width: 100%;
  }

  .expertise-grid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
}

@media (max-width: 480px) {
  .home-container {
    padding: 10px;
  }

  .hero {
    padding: 40px 15px;
  }

  .hero h1 {
    font-size: 1.8em;
  }

  .hero p {
    font-size: 1em;
  }

  h2 {
    font-size: 1.6em;
  }

  .expertise-grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
}
</style>
