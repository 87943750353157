<template>
  <div :class="['demo-site-container', { 'dark-mode': isDarkMode }]">
    <section class="hero">
      <Logo :isDarkMode="isDarkMode" />
      <p>{{ $t('demoSite.heroText') }}</p>
    </section>

    <section class="overview">
      <h2>{{ $t('demoSite.overviewTitle') }}</h2>
      <p>{{ $t('demoSite.overviewContent') }}</p>
    </section>

    <section class="architecture">
      <h2>{{ $t('demoSite.architectureTitle') }}</h2>
      <div class="architecture-cards">
        <div :class="['architecture-card', { 'dark-mode-card': isDarkMode }]">
          <h3>{{ $t('demoSite.vueAppTitle') }}</h3>
          <p>{{ $t('demoSite.vueAppContent') }}</p>
        </div>
        <div :class="['architecture-card', { 'dark-mode-card': isDarkMode }]">
          <h3>{{ $t('demoSite.apiTitle') }}</h3>
          <p>{{ $t('demoSite.apiContent') }}</p>
          <p>
            <a href="https://dev1api.azurewebsites.net/" target="_blank" class="swagger-link">{{ $t('demoSite.apiSwaggerLink') }}</a>
          </p>
        </div>
      </div>
    </section>

    <section class="tech-stack">
      <h2>{{ $t('demoSite.techStackTitle') }}</h2>
      <div class="tech-stack-grid">
        <div class="tech-stack-item" v-for="(item, index) in $tm('demoSite.techStackItems')" :key="index">{{ item }}</div>
      </div>
    </section>

    <section class="contact">
      <h2>{{ $t('demoSite.contactTitle') }}</h2>
      <p>{{ $t('demoSite.contactContent') }}</p>
      <button @click="navigateToContact">{{ $t('demoSite.contactButton') }}</button>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Logo from '@/components/Logo.vue';

export default defineComponent({
  name: 'DemoSite',
  components: {
    Logo,
  },
  props: {
    isDarkMode: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    navigateToContact() {
      this.$router.push('/contact-us');
    },
  },
});
</script>

<style scoped>
.demo-site-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto', Arial, sans-serif;
  padding: 20px;
  transition: background-color 0.3s, color 0.3s;
  user-select: none;
  max-width: 100%;
  overflow-x: hidden;
}

.demo-site-container.dark-mode {
  background-color: #222;
  color: #f8f9fa;
}

.hero {
  text-align: center;
  padding: 25px;
  background-color: #f4f4f4;
  margin-bottom: 40px;
  width: 100%;
  max-width: 800px;
  border-radius: 10px;
  box-sizing: border-box;
}

.demo-site-container.dark-mode .hero {
  background-color: #333;
}

.hero p {
  font-size: 1.2em;
}

.overview, .architecture, .tech-stack, .contact {
  margin-bottom: 60px;
  width: 100%;
  max-width: 800px;
  padding: 0 20px;
  box-sizing: border-box;
}

h2 {
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #007bff;
}

p {
  font-size: 1.1em;
  line-height: 1.6;
}

.architecture-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.architecture-card {
  background-color: #f9f9f9;
  padding: 20px;
  width: 100%;
  max-width: 350px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  box-sizing: border-box;
}

.demo-site-container.dark-mode .architecture-card {
  background-color: #333;
}

.architecture-card h3 {
  font-size: 1.3em;
  margin-bottom: 15px;
  color: #007bff;
}

.swagger-link {
  display: block;
  margin-top: 15px;
  color: #007bff;
  text-decoration: underline;
  transition: color 0.3s;
}

.swagger-link:hover {
  color: #0056b3;
}

.tech-stack-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 15px;
}

.tech-stack-item {
  background-color: #007bff;
  color: #fff;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  font-weight: 500;
  transition: background-color 0.3s, transform 0.3s;
  text-align: center;
}

button {
  padding: 12px 24px;
  font-size: 1.1em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 15px;
}

button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
  transition: background-color 0.3s, transform 0.3s;
}

@media (max-width: 768px) {
  .hero {
    padding: 60px 20px;
  }

  .hero p {
    font-size: 1.1em;
  }

  h2 {
    font-size: 1.8em;
  }

  .architecture-card {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .demo-site-container {
    padding: 10px;
  }

  .hero {
    padding: 40px 15px;
  }

  .hero p {
    font-size: 1em;
  }

  h2 {
    font-size: 1.6em;
  }

  .tech-stack-grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
}
</style>
