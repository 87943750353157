import { Module, ActionContext } from 'vuex';
import { sendVerification, verifyCode, SendVerificationRequest, VerifyRequest } from '@/services/api.service';

export interface RootState {
  auth: AuthState;
  // Add other module states here
}

export interface AuthState {
  status: 'idle' | 'loading' | 'success' | 'error';
  token: string | null;
  email: string | null;
  error: string | null;
}

interface DecodedToken {
  email: string;
  exp: number;
}

function decodeToken(token: string): DecodedToken | null {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
    return JSON.parse(jsonPayload);
  } catch (e) {
    console.error('Error decoding token', e);
    return null;
  }
}

function isTokenExpired(token: string): boolean {
  const decodedToken = decodeToken(token);
  if (!decodedToken) return true;
  const currentTime = Math.floor(Date.now() / 1000);
  return decodedToken.exp < currentTime;
}

export const authStore: Module<AuthState, RootState> = {
  namespaced: true,
  state: {
    status: 'idle',
    token: sessionStorage.getItem('jwt_token'),
    email: null,
    error: null,
  },
  mutations: {
    setStatus(state: AuthState, status: 'idle' | 'loading' | 'success' | 'error') {
      state.status = status;
    },
    setToken(state: AuthState, token: string | null) {
      state.token = token;
      if (token) {
        sessionStorage.setItem('jwt_token', token);
      } else {
        sessionStorage.removeItem('jwt_token');
      }
    },
    setEmail(state: AuthState, email: string | null) {
      state.email = email;
    },
    setError(state: AuthState, error: string | null) {
      state.error = error;
    },
  },
  actions: {
    async sendVerification(
      { commit }: ActionContext<AuthState, RootState>,
      payload: SendVerificationRequest
    ) {
      commit('setStatus', 'loading');
      try {
        await sendVerification(payload);
        commit('setStatus', 'success');
      } catch (error) {
        commit('setStatus', 'error');
        commit('setError', error instanceof Error ? error.message : 'An unknown error occurred');
        throw error;
      }
    },
    async verifyCode(
      { commit }: ActionContext<AuthState, RootState>,
      payload: VerifyRequest
    ) {
      commit('setStatus', 'loading');
      try {
        const response = await verifyCode(payload);

        const decodedToken = decodeToken(response.token);
        if (decodedToken && decodedToken.email) {
          commit('setEmail', decodedToken.email);
        }
        
        commit('setToken', response.token);
        commit('setStatus', 'success');
      } catch (error) {
        commit('setStatus', 'error');
        commit('setError', error instanceof Error ? error.message : 'An unknown error occurred');
        throw error;
      }
    },
    initializeAuth({ commit, state, dispatch }) {
      if (state.token) {
        if (isTokenExpired(state.token)) {
          dispatch('logout');
        } else {
          const decodedToken = decodeToken(state.token);
          if (decodedToken && decodedToken.email) {
            commit('setEmail', decodedToken.email);
          }
        }
      }
    },
    logout({ commit }: ActionContext<AuthState, RootState>) {
      commit('setToken', null);
      commit('setEmail', null);
      commit('setStatus', 'idle');
    },
    checkTokenExpiration({ state, dispatch }) {
      if (state.token && isTokenExpired(state.token)) {
        dispatch('logout');
      }
    },
  },
  getters: {
    isAuthenticated: (state: AuthState) => !!state.token && !isTokenExpired(state.token),
    authStatus: (state: AuthState) => state.status,
    authError: (state: AuthState) => state.error,
    getToken: (state: AuthState) => state.token,
    getEmail: (state: AuthState) => state.email,
  },
};